import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { CleverFormattedMessage } from './CleverFormattedMessage';
import { HeaderImage } from '../HeaderImage';
import { IFile } from '../types';
import { useIntl } from 'react-intl';


const useStyles = (extendsStyle:any) => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    text: {
      backgroundColor: 'white',
      textAlign: 'center',
      padding: '50px 0px 50px 0px',
    },
    headerImage: {
      flexGrow: 1,
      backgroundSize: "cover",
      backgroundPosition: "center",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: "relative",
      backgroundRepeat: "no-repeat",
      backgroundColor: "",
      height: "225px",
      [theme.breakpoints.down("sm")]: {
          height: "125px",
      },
      [theme.breakpoints.up("xl")]: {
          height: "325px",
      }
    },
    ...extendsStyle
  }),
);

interface SimpleRichTextProps {
  titleId: string;
  contentId: string;
  headerImage?: IFile;
  style?: any;
}

export function SimpleRichText(props: SimpleRichTextProps) {
  const classes = useStyles(props.style || {})();
  const intl = useIntl();
  const title = intl.formatMessage({ id: props.titleId });
  const content = intl.formatMessage({ id: props.contentId });

  return (
    <div className={classes.root}>
      
      {props.headerImage && (
        <HeaderImage title={title} img={props.headerImage.file + "?size=original"}/>
      )}

      {!props.headerImage && (
        <HeaderImage title={title}/>
      )}

      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        //spacing={5}
        className={classes.text}
      > 
        <Grid md={8} xs={12}>
          <Typography component="p">
            <CleverFormattedMessage id={content} />
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}
