import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import {Link, useHistory, useParams} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import {Login} from '../Login';
import AccountCircle from "@material-ui/icons/AccountCircle";
import {CleverFormattedMessage} from '../Texts/CleverFormattedMessage';
import {Nullable} from "../types";
import {TextField} from "@material-ui/core";
import {useIntl} from "react-intl";
import SearchIcon from '@material-ui/icons/Search';

export const dropdownRedirectGen = (classes:Record<string, string> = {}) => (link: SlugTitle, includeMenuItem=false) => {
  let toDisplay = link.titleKey ? <CleverFormattedMessage id={link.titleKey} /> : <span>{link.title}</span>;
  if(includeMenuItem){
    toDisplay = <MenuItem>{toDisplay}</MenuItem>
  }
  return (<div>
    {!link.slug.startsWith('/') && (
      <a href={link.slug} className={classes.dropdownLink} key={link.slug} target="_blank">{toDisplay}</a>
    )}
    {link.slug.startsWith('/') && (
      <Link className={classes.dropdownLink} to={link.slug} key={link.slug}>{toDisplay}</Link>
    )}
  </div>)
};

const useStyles = (extendsStyle:any, zIndex="1400") => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "& a": {
        textDecoration: "none"
      },
      boxShadow: "none"
    },
    button: {
      color: theme.palette.primary.main,
      '&:hover': {
        color: theme.palette.secondary.main
      }
    },
    buttonRight: {
      padding: '8px',
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.secondary.main,
      }
    },
    link: {
      color: theme.palette.primary.main,
      margin: theme.spacing(2),
      zIndex,
      [theme.breakpoints.down("sm")]: {
        marginLeft: "5px"
      },
      '&:hover': {
        color: theme.palette.secondary.main
      }
    },
    activeButton: {
      color: theme.palette.secondary.main
    },
    right: {
      textAlign: "end",
      textTransform: 'uppercase',
      flexGrow: 1,
      '& > *': {
        justifyContent: 'flex-end'
      }
    },
    slideInterstice:{
      color: "#9B9B9B",
    },
    slideAccount:{
      color: "#9B9B9B",
      paddingTop: "32px",
    },
    svg: {
      verticalAlign: "middle",
      fontSize: "2.5rem"
    },
    dropdownLink: {
      textTransform: 'uppercase',
      color: theme.palette.primary.main,
    },
    dropdown: {
      marginTop: "80px",
      maxHeight: 'calc(100%)',
      '& .MuiPaper-root': {
        top: '-16px !important'
      }
    },
    swiper: {
      zIndex: "2000 ! important",
      '& .MuiPaper-root': {
        color: theme.palette.primary.main
      }
    },
    list: {
      width: 250,
    },
    logo: {
      maxWidth: 220,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 120
      },
      maxHeight: 70,
    },
    username: {
      [theme.breakpoints.up("sm")]: {
        order: 2,
        marginLeft: "1rem"
      }
    },
    searchBar: {
      padding: "10px",
      width: '40%',
      '& input': {
        backgroundColor: theme.palette.primary.main,
        height: 0,
        borderRadius: "10px"
      },
      '& fieldset': {
        borderRadius: "10px"
      }
    },
    ...extendsStyle
  })
);

export {useStyles as useStylesNavbar};

interface SlugTitle {
  slug: string;
  title?: string;
  titleKey?: string;
  customComponent?: JSX.Element;
}

interface Dropdown {
    title: string;
    slug?: string;
    links: SlugTitle[];
    customIcon?:JSX.Element;
}

interface NavbarProps {
  logo: string;
  linksLeft: SlugTitle[];
  linksRight: SlugTitle[];
  linksMiddle?: SlugTitle[];
  login?: boolean;
  loggedInUser?: string | null;
  searchBar?: boolean;
  loginRedirectUrl?: string;
  style?: any;
  dropdowns?: Dropdown[];
  customComponent?: JSX.Element;
  translationComponent?: JSX.Element;
  useLoginPageUrl?: string;
  forgottenPassword?: Nullable<JSX.Element>;
  onSearch?: Nullable<(search: string) => void>;
  searchProvider?: Nullable<"routing">;
  accountUrl?: Nullable<string>;
  langButton?:boolean;
}

export function Navbar(props: NavbarProps) {
  let classes = useStyles({})();
  if(props.style){
    classes = useStyles(props.style)();
  }
  const [state, setState] = React.useState({ right: false });

  const [loginOpen, setLoginOpen] = React.useState(false);

  const history = useHistory();
  const accountUrl = props.accountUrl || "/account";

  const handleLoginOpen = () => {
    if (props.useLoginPageUrl){
      history.push(props.useLoginPageUrl);
      //TODO: return; ?
    }
    setLoginOpen(true);
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
  };

  const toggleDrawer = (open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, right: open });
  };

  const coreAccountElement = (
    <Button className={classes.button}>
      <span className={classes.username}>{props.loggedInUser}</span>
      <AccountCircle className={classes.svg} />
    </Button>
  );
  let myAccountElement = (
    <Link
      className={classes.dropdownLink}
      to={accountUrl}
      key={"account_button"}
    >
      {coreAccountElement}
    </Link>
  );

  if (!accountUrl.startsWith("/")) {
    myAccountElement = (
      <a href={accountUrl} rel="noopener noreferrer" target="_blank">
        {coreAccountElement}
      </a>
    );
  }

  const loggedUser = () => (
    props.login &&
    <React.Fragment>
        {props.loggedInUser && myAccountElement}
        {!props.loggedInUser && (
          <Button className={classes.button} onClick={handleLoginOpen}>
              <CleverFormattedMessage id="login" />
              <AccountCircle className={classes.svg} />
          </Button>
        )}
    </React.Fragment>
  );

  const { formatMessage } = useIntl();
  const searchBarPlacehoder = formatMessage({id: 'search_bar_placeholder'})

  let { search } = useParams<{search:string}>();

  let initialSearchValue = "";
  if(props.searchProvider === "routing"){
    initialSearchValue = search;
  }

  const searchBar = () => (
    props.searchBar &&
    <React.Fragment>
      <TextField
        variant="outlined"
        margin="none"
        required
        placeholder={searchBarPlacehoder}
        autoFocus
        className={classes.searchBar}
        defaultValue={initialSearchValue}
        onChange={(event) => props.onSearch && props.onSearch(event.target.value)}
      />
    </React.Fragment>
  );

  const dropdownRedirect = dropdownRedirectGen(classes);

  const linksRedirect = (link: SlugTitle) => (
    <div key={link.title}>
      {!link.slug.startsWith('/') && (
        <a href={link.slug} className={classes.link} key={link.slug} target="_blank">
          <Button className={classes.button}>
            <CleverFormattedMessage id={link.title} />
          </Button>
        </a>
      )}
      {link.slug.startsWith('/') && (
        <Link className={classes.link} to={link.slug} key={link.slug}>
          <Button className={classes.button}>
            <CleverFormattedMessage id={link.title} />
          </Button>
        </Link>
      )}
    </div>
  );

  const mobileSideList = () => (
    <div
      className={classes.list}
      role="presentation"
      //onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List className={classes.right}>

        {props.dropdowns &&
          props.dropdowns.map(dropdown => (
            <React.Fragment key={dropdown.title}>
              <ListItem className={classes.slideInterstice}><CleverFormattedMessage id={dropdown.title}/></ListItem>
              {dropdown.links.map((link: any) => (
                  <ListItem button key={link.title}>
                   {dropdownRedirect(link)}
                  </ListItem>
              ))}
            </React.Fragment>
          ))
        }
        {props.linksMiddle && props.linksMiddle.map(link => (
          <ListItem button key={link.title}>
            {dropdownRedirect(link)}
         </ListItem>
        ))}
        {props.linksRight.map(link => (
          <ListItem button key={link.title}>
            {dropdownRedirect(link)}
         </ListItem>
        ))}
        {props.translationComponent && props.translationComponent}
        <ListItem className={classes.slideAccount}>
          <Link className={classes.dropdownLink} to={accountUrl} key={'account_button'}><CleverFormattedMessage id="account" /></Link>
        </ListItem>
        {loggedUser()}
      </List>
    </div>
  );

  const renderDesktop = () => {
    return <Hidden smDown>
          {props.dropdowns &&
            props.dropdowns.map((dropdown,index) => (
              <MenuDropDown dropdown={dropdown} index={index} key={index} classes={classes} dropdownRedirect={dropdownRedirect}/>
            ))}
            {props.linksMiddle && (
              <Box>
                  {props.linksMiddle.map(link => (
                    <Link className={classes.link} to={link.slug}>
                      <Button className={classes.button}><CleverFormattedMessage id={link.titleKey} /></Button>
                    </Link>
                  ))}
              </Box>
            )}
            <Box className={classes.right}>
              {props.linksRight.map(link => {
                return <Link className={classes.link} to={link.slug}>
                  <Button className={classes.buttonRight}><CleverFormattedMessage id={link.titleKey} /></Button>
                </Link>
                })}
              {searchBar()}
              {props.translationComponent && props.translationComponent}
              {loggedUser()}
            </Box>
            {props.customComponent && props.customComponent}
        </Hidden>
  }

  const searchBarMobile = () => (
    props.searchBar &&
    <React.Fragment>
      <Link to="/search">
        <IconButton aria-label="search" color="primary">
          <SearchIcon />
        </IconButton>
      </Link>
    </React.Fragment>
  );

  const renderMobile = () => {
    return <Hidden mdUp>
          {props.customComponent && props.customComponent}
          <Box display="flex" flexGrow={1} justifyContent="flex-end">
          {searchBarMobile()}
            <IconButton onClick={toggleDrawer(true)} edge="start" color="inherit" aria-label="menu">
              <MenuIcon />
            </IconButton>
          </Box>
          <SwipeableDrawer
          anchor="right"
          open={state.right}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          className={classes.swiper}
          >
          {mobileSideList()}
          </SwipeableDrawer>
        </Hidden>
  }

  return (
    <AppBar color="secondary" position="static" className={classes.root}>
      <Toolbar>
        <Link to="/">
          <img className={classes.logo} src={props.logo} alt="Logo" />
        </Link>
        {props.linksLeft.map(link => linksRedirect(link))}
        {renderDesktop()}
        {renderMobile()}
      </Toolbar>
      <Login open={loginOpen} handleLoginClose={handleLoginClose} redirectUrl={props.loginRedirectUrl} forgottenPassword={props.forgottenPassword}/>
    </AppBar>
  );
}

interface MenuDropDownProps {
  dropdown: Dropdown;
  index: number;
  dropdownRedirect: (link: any, includeMenuItem?: boolean) => JSX.Element;
  classes: Record<string, string>;
  noHoverMode?: boolean;
}

export const MenuDropDown = (props: MenuDropDownProps) => {
  const dropdown = props.dropdown;
  let classes = props.classes;

  const timeoutLength = 150;

  const [anchorEl, setAnchorEl] = React.useState<Nullable<HTMLElement>>(null);
  const [mouseOverButton, setMouseOverButton] = React.useState(false);
  const [mouseOverMenu, setMouseOverMenu] = React.useState(false);

  const openDropDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setMouseOverButton(true);
  };

  const closeDropdown = () => {
    setMouseOverButton(false);
    setMouseOverMenu(false);
  };

  const leaveButton = () => {
    setTimeout(() => {
      setMouseOverButton(false);
    }, timeoutLength);
  }

  const enterMenu = () => {
    setMouseOverMenu(true);
  }

  const leaveMenu = () => {
    setTimeout(() => {
    setMouseOverMenu(false);
    }, timeoutLength);
  }

  const isActive = mouseOverButton || mouseOverMenu;

  const iconToDisplay = dropdown.customIcon || <ExpandMoreIcon />;
  let coreButtonToRender = (
    <Button
      className={`${isActive ? classes.activeButton : ""} ${classes.link}`}
      color="primary"
      aria-controls={"simple-menu" + props.index}
      aria-haspopup="true"
      onMouseEnter={openDropDown}
      onMouseLeave={() => leaveButton()}
    >
      <CleverFormattedMessage id={dropdown.title} />
      {iconToDisplay}
    </Button>
  );

  if (props.noHoverMode) {
    coreButtonToRender = (
      <Button
        className={`${isActive ? classes.activeButton : ""} ${classes.link}`}
        color="primary"
        aria-controls={"simple-menu" + props.index}
        aria-haspopup="true"
        onClick={(event) => {
          if(mouseOverButton){
            setMouseOverButton(false);
          }else{
            openDropDown(event);
          }
        }}
      >
        <CleverFormattedMessage id={dropdown.title} />
        {iconToDisplay}
      </Button>
    );
  }

  let mainButtonRendered = coreButtonToRender;
  if (dropdown.slug) {
    mainButtonRendered = (
      <Link className={classes.link} to={dropdown.slug}>
        {coreButtonToRender}
      </Link>
    );
  }

  return (
    <React.Fragment>
      {mainButtonRendered}
      <Menu
        id={"simple-menu"+props.index}
        anchorEl={anchorEl}
        keepMounted
        elevation={0}
        open={isActive}
        className={classes.dropdown}
        getContentAnchorEl={null}
        onClose={closeDropdown}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        MenuListProps={{
          onMouseEnter: () => enterMenu(),
          onMouseLeave: () => leaveMenu()
        }}
      >
        {dropdown.links.map((link: SlugTitle) => (
          link.customComponent || props.dropdownRedirect(link, true)
        ))}
      </Menu>
    </React.Fragment>
  )
}
