import { trim } from "lodash";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useEffect, useRef } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { AppState, IFile, IText, JwtPayload } from "./types";
import { flexibleGetEnv } from "./utils";
import { wrapperObject } from "./libWrapper"
import { getKeyWithPrefixIfExistant } from "./videokubeUtils";

//TODO: here we find custom Mobibase hooks and generic hooks. Maybe split them!

export function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef() as any;

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}

export const useCanonicalUrl = () => {
  const location = useLocation();

  const currentPagePefix = window.location.protocol + "://" + window.location.host;
  const prefix = flexibleGetEnv("REACT_APP_CANONICAL_PREFIX") || currentPagePefix;
  let fullPath = prefix+location.pathname;
  //We don't want trailing /
  fullPath = trim(fullPath,"/");
  return fullPath;
}


export const useSuffixCore = (key:string) => () => {
  const { formatMessage } = useIntl();

  const suffix = formatMessage({id:key});

  return (aString: string) => {
    return aString + " - " + suffix;
  }
}

export const useSuffixDescription = useSuffixCore("suffix_description");

export const useSuffixTitle = useSuffixCore("suffix_title");

export const useLibWrapper = () => {
  const dispatch = useDispatch();
  const jwtPayload = useSelector<AppState, JwtPayload>((state) => state.app.jwtPayload)
  const snackbar = useSnackbar();
  const { formatMessage } = useIntl();
  const libWrapper = wrapperObject(dispatch, jwtPayload, snackbar, formatMessage);
  return libWrapper;
}

export const useFile = (name: string, handlePrefix = true) => {
  const filesDict = useSelector<AppState, Record<string,IFile>>((state) => state.app.filesDict);
  let appropriateName = name;
  if(handlePrefix){
    appropriateName = getKeyWithPrefixIfExistant(filesDict, name);
  }
  return filesDict[appropriateName];
}

export const useText = (name: string, handlePrefix = true) => {
  const textsDict = useSelector<AppState, Record<string,IText>>((state) => state.app.textsDict);
  let appropriateName = name;
  if(handlePrefix){
    appropriateName = getKeyWithPrefixIfExistant(textsDict, name);
  }
  return textsDict[appropriateName];
}
