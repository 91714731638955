import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import { Link } from "react-router-dom";
import { DurationFormat } from "../utils";
import { useIntl } from "react-intl";

export interface SimpleVideoCardProps {
  title: string;
  img?: string;
  duration?: number;
  path?: string;
  backgroundMode?: boolean;
  height?: number;
}

SimpleVideoCard.defaultProps = {
  height: 200
};

export function SimpleVideoCard(props: SimpleVideoCardProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const renderCardMedia = () => {
    if (props.backgroundMode) {
      return (
        <CardMedia
          component="div"
          image={props.img}
          style={{ height: props.height, width: "100%" }}
          className={classes.img}
        />
      );
    }
    return (
      <CardMedia
        component="img"
        alt={props.title}
        image={props.img}
        title={props.title}
        className={classes.img}
      />
    );
  };

  return (
    <Card className={classes.card} raised={false}>
      <Link to={props.path ? props.path : "/"}>
        <CardActionArea>
          <div className={classes.svgPlayer}>
            {renderCardMedia()}
            
            <PlayCircleOutlineIcon className={classes.play} />

            <Typography variant="h6" component="h6" className={classes.textLeft}>
              {props.title}
            </Typography>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                component="p"
                className={classes.textRight}
              >
                {(() => {
                  if (props.duration) {
                    return DurationFormat(props.duration, formatMessage);
                  }
                  return;
                })()}
              </Typography>
          </div>
        </CardActionArea>
      </Link>
    </Card>
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      boxShadow: "none",
      borderRadius: "0px"
    },
    img: {
      border: "1px solid #707070",
      filter: 'brightness(70%)'
    },
    svgPlayer: {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-start',
      "&:hover": {
        "& svg": {
          opacity: 100,
          transition: "opacity 0.25s ease-in"
        }
      }
    },
    play: {
      position: "absolute",
      color: "white",
      fontSize: "5rem",
      opacity: 0,
      bottom: 'calc(50% - 40px)',
      left: 'calc(50% - 40px)'
    },
    textRight: {
      backgroundColor: 'rgba(255, 255, 255, 0.85)',
      borderRadius: '4px',
      position: 'absolute',
      bottom: '4px',
      right: '4px',
      padding: '2px',
    },
    textLeft: {
      backgroundImage: 'linear-gradient(to bottom, rgb(0, 0, 0), rgba(0, 0, 0, 0))',
      position: 'absolute',
      top: '0px',
      left: '0px',
      right: '0px',
      padding: '4px',
      color: 'white',
      paddingBottom: '20px',
    }
  })
);
