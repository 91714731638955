import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import moment from "moment";
import ShowMoreText from "react-show-more-text";

export interface PaperCardProps {
  title: string;
  category: string;
  date: Date | string;
  link: string;
  source: string;
  description: string;
  showMore?: boolean;
}

export function PaperCard(props: PaperCardProps) {
  const classes = useStyles();

  return (
    <Grid container className={classes.card}>
      <Grid item xs={2}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.boxItem}
        >
          <Box display="flex">
            <InsertDriveFileIcon className={classes.svg} />
          </Box>
          <Box display="flex">
            <Typography
              variant="overline"
              component="p"
              className={classes.fileType}
            >
              {props.link}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={10}>
        <Box display="flex" mb={1}>
          <Box flexGrow={1}>
            <Typography
              color="textSecondary"
              className={classes.categoryDate}
              gutterBottom
            >
              {props.category}
            </Typography>
          </Box>
          <Box>
            <Typography
              component="p"
              variant="overline"
              className={classes.categoryDate}
              gutterBottom
            >
              {typeof props.date === 'string' ? props.date : moment(props.date)
                .format("DD/MM/YYYY")
                .toString()}
            </Typography>
          </Box>
        </Box>
        <Typography component="h6" variant="h6" className={classes.title}>
          {props.title}
        </Typography>
        <Typography
          component="div"
          variant="caption"
          className={classes.description}
          gutterBottom
        >
          {props.showMore && (
              <ShowMoreText
                lines={1}
                width={1300}
                more="Read description"
                less="Less"
                anchorClass="MuiTypography-subtitle1"
              >
                  <span>{props.description}</span>
              </ShowMoreText>
          )}
          {!props.showMore && <div><span>{props.description}</span></div>}
        </Typography>
        <Typography
          component="p"
          variant="subtitle1"
          className={classes.source}
          gutterBottom
        >
          SOURCE : {props.source}
        </Typography>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  card: {
    borderRadius: "3px",
    border: "1px solid #9b9b9b",
    padding: "10px",
    height: "100%"
  },
  boxItem: {
    height: "100%"
  },
  svg: {
    fontSize: "4rem",
    color: "#cccccc"
  },
  fileType: {
    fontWeight: "bold"
  },
  categoryDate: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "0.7em",
    color: "#9B9B9B",
    letterSpacing: "1px"
  },
  title: {
    fontSize: "1rem",
    minHeight: "30px",
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  description: {
    lineHeight: "13px",
    color: "#999999",
    opacity: "0.9",
    fontSize: "0.7em"
  },
  source: {
    textTransform: "uppercase",
    fontSize: "0.6em",
    fontWeight: "bold",
    letterSpacing: "0.45px",
    marginTop: "10px"
  }
});
