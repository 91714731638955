import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export interface IFrameViewerProps {
  src: string;
  title?: string;
}

export function IFrameViewer(props: IFrameViewerProps) {
  const classes = useStyles();

  return (
    <div className={classes.iframeContainer}>
      <iframe className={classes.iframe}
        title={props.title}
        src={props.src}
      ></iframe>
    </div>
  );
}

const useStyles = makeStyles({
  iframeContainer: {
    //overflow: "hidden",
    //position: "relative",
    // Calculated from the aspect ration of the content (in case of 16:9 it is 9/16= 0.5625)
    //paddingTop: "56.25%",
    //minHeight: "800px",
    height: "85vh",
  },

  iframe: {
    border: "0",
    height: "100%",
    left: "0",
    top: "0",
    width: "100%",
    bottom: "0",
    right: "0",
    maxHeight: "100%",
    maxWidth: "100%",
  }
});
