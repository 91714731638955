import React from "react";
import { SocialMediaCallback } from "../SocialMediaCallback";
import GoogleLogin, {GoogleLoginResponse, GoogleLoginResponseOffline} from "react-google-login";
import { billingLogin } from "../../api";
import { JwtPayload } from "../../types";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    width: '100%'
  },
}));
interface GoogleButtonLogin extends SocialMediaCallback {
  message: string;
  apiID: string;
}

export function GoogleButton(googleProps: GoogleButtonLogin) {
  const classes = useStyles();

  const onLogin = async (response: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    return billingLogin({
      access_token: (response as GoogleLoginResponse).tokenId,
      provider: "google",
      create: true
    }).then((jwt: JwtPayload) => googleProps.callback(jwt));
  };

  return (
    <GoogleLogin
      className={classes.root}
      clientId={googleProps.apiID}
      buttonText={googleProps.message}
      onSuccess={onLogin}
      isSignedIn={false}
    />
  );
}
