import moment from "moment";
import { Media } from "./types";
import { orderBy } from "lodash";
import { countries } from "./countries";
import { flexibleGetEnv } from "./utils";
import { getDefaultState } from "./defaultState";
import TagManager from 'react-gtm-module';

export const getCurrentProgram = (channel: Media, currentTime = moment()) => {
  return channel.programs.find(
    p => moment(p.start) <= currentTime && moment(p.end) >= currentTime
  );
};

export const getNextProgram = (channel: Media, currentTime = moment()) => {
  const sortedChannels = orderBy(channel.programs, x => x.start);
  return sortedChannels.find(p => moment(p.start) >= currentTime);
};

export { getDefaultState }

export const feedMetaDatas = (anEpisode: Media) => {
  if(!anEpisode?.metadatas.length){
    return anEpisode;
  }
  anEpisode.show = anEpisode.metadatas.find(x => x.name === "show")?.value;
  const seasonString = anEpisode.metadatas.find(x => x.name === "season")?.value;
  const episodeString = anEpisode.metadatas.find(x => x.name === "episode")?.value;
  anEpisode.season = seasonString ? parseInt(seasonString,10) : null;
  anEpisode.episode = episodeString ? parseInt(episodeString,10) : null;
  const imdbRateString = anEpisode.metadatas.find(x => x.name === "imdbRate")?.value;
  anEpisode.imdbRate = imdbRateString ? parseFloat(imdbRateString) : null;
  return anEpisode;
}

export const getCountry = () => {
  const data = { countries };
  return data.countries.find(e => e.code === flexibleGetEnv("REACT_APP_COUNTRY"));
}

export const getFacebookAppId = () => {
  return flexibleGetEnv("REACT_APP_FACEBOOK_APP_ID") as string;
}

export const getGoogleAppId = () => {
  return flexibleGetEnv("REACT_APP_GOOGLE_APP_ID") as string;
}

export const getCaptchaSiteKey = () => {
  return flexibleGetEnv("REACT_APP_CAPTCHA_KEY") as string;
}

export const getReactAppGTM = () => {
  const reactAppGTM = flexibleGetEnv("REACT_APP_GTM_TAG") as string;
  if (reactAppGTM){
      const tagManagerArgs = {
          gtmId: reactAppGTM
      }

      TagManager.initialize(tagManagerArgs)
  }
}

export const getPaypalClientId = () => {
  return flexibleGetEnv("REACT_APP_PAYPAL") as string;
}

export const isDvMode = () => {
  return flexibleGetEnv("REACT_APP_DV_MODE") === "true";
}

export const isCookiesConsent = () => {
  return flexibleGetEnv("REACT_APP_COOKIES_CONSENT") === "true";
}

export const checkUsernameForNumbersFromCountry = (username: string) => {
  const country = getCountry();
  if (country && country.max && (username.length !== (country.max + (username.includes(country.phone) ? country.phone.length : 0)))) {
    return false;
  }
  let numbers: RegExp = /^\+?[0-9]+$/;

  if(username.match(numbers) == null){
    return false;
  };
  return true;
};

export const normalizeUsernameWithIndicatif = (username: string) => {
  const country = getCountry();
    if(username && country && checkUsernameForNumbersFromCountry(username)){
      return username.includes(country.phone) ? username : country.phone + username;
    }
    return '';
}

export const removeParamsFromParamString = (paramsAsString: string, paramsToRemove: string[]) => {
  const urlParams = new URLSearchParams(paramsAsString);
  const msisdn = urlParams.get('msisdn');
  if(!msisdn){
    return;
  }

  paramsToRemove.forEach(p => {
    urlParams.delete(p);
  })

  if (msisdn &&  checkUsernameForNumbersFromCountry(msisdn)){
    localStorage.setItem('msisdn', msisdn);
  }

  let url = urlParams.toString() ? "?" + urlParams.toString() : "";
  window.location.href = window.location.origin + window.location.pathname + url;
}

export const getKeyWithPrefixIfExistant = (values: any, aKey: string) => {
  const withPrefix = flexibleGetEnv('REACT_APP_SID') + '__' + aKey;
  if (values[withPrefix]) {
    return withPrefix;
  }
  return aKey;
};

export const getChannelToDisplay = (channels: Media[]) => {
  if(!channels || !channels.length){
    return null;
  }
  //We sort by highlight desc
  return orderBy(channels, c => c.highlight, "desc")[0];
}
