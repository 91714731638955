import React from 'react';
import { makeStyles,createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Container, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Category } from '../types';
import {  useIntl } from 'react-intl';

export interface ListCategoriesProps {
  categories?: Array<Category>;
}

export function ListCategories(props: ListCategoriesProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const videos = formatMessage({id: 'videos'});

  return (
    <Box display="flex" justifyContent="center">
        <Container maxWidth="md" className={classes.categoriesRoot}>
            <Grid container justify="center" spacing={3}>
              {props.categories && props.categories.map((category) => (
                <Grid item key={category.id} md={6} xs={12} className={classes.category}>
                  <Link to={'/category/' + category.slug}>
                    <div className={classes.background} style={{ background: 'url('+category.visual+'?size=small)' }}>
                      <Grid item xs={12} className={classes.textContainer}>
                        <div className={classes.text}>
                          <Typography variant="h5" style={{ fontWeight: 600 }}>{category.name}</Typography>

                          <Typography variant="body2" style={{ color: 'white' }}>{category.medias.length} {videos}</Typography>
                        </div>
                      </Grid>
                    </div>
                  </Link>
                </Grid>
              ))}
            </Grid>
        </Container>
    </Box>
  );
}


const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    category: {
      textAlign: 'center',
      overflow: 'hidden',
    },
    background: {
      paddingTop: '25%',
      paddingBottom: '3%',
      backgroundSize: 'cover !important',
      transition: 'all 0.5s',
      backfaceVisibility: 'hidden',
      boxShadow: '0 4px 12px -1.5px rgba(0, 0, 0, 0.3)',
      '&:hover': {
            transform: 'scale(1.1)',
            boxShadow: '0 8px 6px -6px rgba(0,0,0,0.3)',
      }
    },
    textContainer: {
      width: '100%',
      height: '100%'
    },
    text: {
      color: 'white',
      marginTop: '20px'
    },
    categoriesRoot: {
      margin: '20px 0px 20px 0px'
    }
  }),
);
