import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import moment from "moment";

export interface VideoCardProps {
  title: string;
  duration: number;
  img: string;
}

export function VideoCard(props: VideoCardProps) {
  const classes = useStyles();

  return (
    <Card className={classes.card} raised={false}>
      <CardActionArea>
        <div className={classes.player}>
          <CardMedia
            component="img"
            alt={props.title}
            image={props.img}
            title={props.title}
            className={classes.img}
          />
          <PlayCircleOutlineIcon className={classes.play} />
        </div>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="h6" gutterBottom>
            {props.title}
          </Typography>
          <Typography variant="overline" component="p">
            {moment.duration(props.duration, "minutes").humanize()}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

const useStyles = makeStyles({
  card: {
    boxShadow: "none",
    borderRadius: "0px"
  },
  content: {
    letterSpacing: "0.7px",
    padding: "0px",
    marginTop: "10px"
  },
  img: {
    borderRadius: "5px"
  },
  player: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": {
      "& svg": {
        opacity: 100,
        transition: "opacity 0.25s ease-in"
      }
    }
  },
  play: {
    position: "absolute",
    color: "white",
    fontSize: "5rem",
    opacity: 0
  }
});
