import React, { useEffect, useMemo } from "react";
import chromecast from '@silvermine/videojs-chromecast';
//import airPlay from '@silvermine/videojs-airplay';
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { randomElementId } from "../utils";

import "./poster.css"

//videojs.registerPlugin("chromecast",chromecast)
//videojs.registerPlugin("airPlay",airPlay)
chromecast(videojs, { preloadWebComponents: true });
//airplay(videojs);

export interface VideoProps {
  video: string;
  poster?: string;
  autoplay?: boolean;
  muted?: boolean;
  loop?: boolean;
  backgroundMode?: boolean;
  type?: string;
  bigCenteredMode?: boolean;
  style?: any;
}

export function Video(props: VideoProps) {
  const id = useMemo(() => randomElementId("video_"), []);
  const properVideoProps: VideoProps = { ...props };
  let style: any = {};

  if (props.backgroundMode) {
    style = {
      minWidth: "100%",
      minHeight: "100%",
      width: "auto",
      height: "100%",
      objectFit: "cover",
    };
    properVideoProps.autoplay = true;
    properVideoProps.loop = true;
    properVideoProps.muted = true;
  }
  let className = "";
  if (properVideoProps.bigCenteredMode) {
    className = "vjs-big-play-centered video-js vjs-fluid vjs-default-skin";
  }

  if(props.style){
    style = props.style;
  }

  useEffect(() => {
    let options = {
      liveui: true,
      controls: !props.backgroundMode,
      techOrder: [ 'chromecast', 'html5' ],
      plugins: {
         chromecast: {},
         //airPlay: {}
      }
   };

    const player = videojs(id, options);
    //(player as any).chromecast();

    return function cleanup() {
      player?.dispose();
    };
  },[]);

  //TODO: is the type properly guess or should we give it to the source component ? Before we gave it : video/mp4 or application/vnd.apple.mpegurl

  return (
    <div key={properVideoProps.video} style={{height: "100%"}}>
      <video
        id={id}
        className={className}
        poster={properVideoProps.poster}
        autoPlay={properVideoProps.autoplay}
        loop={properVideoProps.loop}
        controls={!properVideoProps.autoplay}
        muted={properVideoProps.muted}
        playsInline={properVideoProps.autoplay}
        style={style}
      >
        <source src={properVideoProps.video} />
      </video>
    </div>
  );
}