import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { CleverFormattedMessage } from '../Texts/CleverFormattedMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.secondary.main,
    },
    img: {
      width: '100%',
      height: '100%',
    },
    textBlock: {
      paddingLeft: '24px',
      paddingRight: '24px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
    text: {
      color: 'white',
    },
  }),
);

interface HalfImageTextProps {
  img: string;
  title: string;
  description: string;
}

export function HalfImageText(props: HalfImageTextProps) {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      className={classes.root}
    >
      <Grid item md={6} xs={12}>
        <img className={classes.img} src={props.img} alt="Fighter" />
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid  className={classes.textBlock}>
          <Typography component="h2" variant="h4" className={classes.text}>
            <CleverFormattedMessage id={props.title} />
          </Typography>
          <Typography component="p" className={classes.text}>
            <CleverFormattedMessage id={props.description} />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
