import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import SwiperCore, { Virtual, Navigation, Autoplay, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FullText } from '../FullText';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

const useStyles = (extendsStyle:any) => makeStyles((theme: Theme) =>
  createStyles({
    containerText: {
      textAlign: 'center',
      zIndex: 20,
      color: '#fff',
      left: '50%',
      bottom: '60px',
    },
    slidedDiv:{
      backgroundSize: 'cover',
      minHeight: "20vw",
      height: 'auto',
      [theme.breakpoints.down('sm')]: {
        minHeight: '60vw',
      },
    },
    ...extendsStyle
  }
  )
);

interface Slider {
  titleId?: string;
  title?: string;
  descriptionId?: string;
  description?: string;
  image: string;
}

interface SliderProps {
  slides: Array<Slider>;
  backgroundImagePrefix?: string;
  slideMaxHeight?: string;
  useImage?: boolean;
  style?: any;
}

SwiperCore.use([Virtual, Navigation, Autoplay, Scrollbar]);

export const Slider = (props: SliderProps) => {
  const classes = useStyles(props.style || {})();;
  const params = {
    virtual: true,
    navigation: true,
    scrollbar: {
      draggable: true
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: true
    }
};
  const getBackgroundImageProperty = (slide:Slider) => {
    let backgroundImage = `url(${slide.image})`;
    if(props.backgroundImagePrefix){
      backgroundImage = props.backgroundImagePrefix  + "," + backgroundImage;
    }
    return backgroundImage;
  }

  const getSlideMaxHeightProperty = () => {
    let slideMaxHeight = "40vw";
    if(props.slideMaxHeight){
      slideMaxHeight = props.slideMaxHeight;
    }
    return slideMaxHeight;
  }
  return (
    <React.Fragment>
    {props.useImage && (
      <Swiper {...params} navigation >
      {props.slides.map((slide, key) => (
        <SwiperSlide key={key} virtualIndex={key}>
          <img src={slide.image} style={{width: '100%'}}/>
        </SwiperSlide>
      ))}
    </Swiper>
    )}
    {!props.useImage && (
      <Swiper {...params}>
        {props.slides.map((slide, key) => (
          <SwiperSlide key={slide.title} virtualIndex={key}>
            <div key={key} style={{ backgroundImage: getBackgroundImageProperty(slide), maxHeight: getSlideMaxHeightProperty()}} className={classes.slidedDiv}>
              <FullText title={slide.title} description={slide.description} color="white"/>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    )}
    </React.Fragment>
  );
};
