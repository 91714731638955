import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { CleverFormattedMessage } from "./CleverFormattedMessage";

const useStyles = (extendsStyle:any ) => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignSelf: 'center'
    },
    seeAll: {
      textTransform: "uppercase",
      letterSpacing: "1px",
      color: theme.palette.primary.main,
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      fontSize: "10px"
    },
    svg: {
      verticalAlign: "text-bottom",
      fontSize: "10px"
    },
    ...extendsStyle
  })
);

SeeAll.defaultProps = {
  textId: 'see_all'
};

export interface SeeAllProps {
  textId: string;
  path: string;
  style?:any;
}

export function SeeAll(props: SeeAllProps) {
  let classes = useStyles({})();
  if(props.style){
    classes = useStyles(props.style)();
  }

  return (
    <Box className={classes.root} alignSelf="end">
      <Link to={props.path}>
        <Typography
          component="div"
          variant="caption"
          color="primary"
          className={classes.seeAll}
        >
          <CleverFormattedMessage id={props.textId} />
          <ArrowForwardIosIcon className={classes.svg} />
        </Typography>
      </Link>
    </Box>
  );
}
