import React from "react";
import { useSelector } from "react-redux";
import { AppState, IText } from "../types";
import { getKeyWithPrefixIfExistant } from "../videokubeUtils";
import { HtmlFormattedMessage } from "./HtmlFormattedMessage";
import { TextFormattedMessage } from "./TextFormattedMessage";

export const CleverFormattedMessage = (props: any) => {
  const values = useSelector<AppState, Record<string, IText>>(
    state => state.app.textsDict
  );

  // TODO: use useText instead?
  const properId = getKeyWithPrefixIfExistant(values, props.id);

  if (values[props.id]?.type === "text") {
    return <TextFormattedMessage {...props} id={properId} />;
  }
  return <HtmlFormattedMessage {...props} id={properId} />;
};
