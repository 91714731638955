import React from "react";
import { useIntl } from "react-intl";

export const HtmlFormattedMessage = (props: any) => {
  const { formatMessage } = useIntl();

  function createMarkup() {
    return { __html: formatMessage({ id: props.id }) };
  }

  return <div className={props.className} style={props.style} dangerouslySetInnerHTML={createMarkup()} />;
};