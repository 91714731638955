import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { IFile } from '../types';


const useStyles = makeStyles(() =>
  createStyles({
    img: {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      position: 'relative',
      padding: '0px',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    container: {
      flexWrap: 'nowrap',
      display: 'flex',
      height: '320px',
      width: '100%',
    },
  }),
);

interface SideBySideImageProps {
  imgs: Array<IFile>;
}

export function SideBySideImage(props: SideBySideImageProps) {
  const length = { length: props.imgs.length };
  const classes = useStyles(length);

  return (
      <div className={classes.container} >
          {props.imgs[5] && (
            <Hidden only={['xs','sm','md', 'lg', 'xl']}>
              <div  className={classes.img} style={{ backgroundImage: 'url(' + props.imgs[5].file + ')', maxHeight: '500px', height: 'auto', padding: '0px', width: '100%', minWidth: '200px'}}/>
            </Hidden>
          )}
          {props.imgs[4] && (
            <Hidden only={['xs','sm','md', 'lg']}>
              <div  className={classes.img} style={{ backgroundImage: 'url(' + props.imgs[4].file + ')', maxHeight: '500px', height: 'auto', padding: '0px', width: '100%', minWidth: '200px'}}/>
            </Hidden>
          )}
          {props.imgs[3] && (
            <Hidden only={['xs','sm','md']}>
              <div  className={classes.img} style={{ backgroundImage: 'url(' + props.imgs[3].file + ')', maxHeight: '500px', height: 'auto', padding: '0px', width: '100%', minWidth: '200px'}}/>
            </Hidden>
          )}
          {props.imgs[2] && (
            <Hidden only={['xs','sm']}>
              <div  className={classes.img} style={{ backgroundImage: 'url(' + props.imgs[2].file + ')', maxHeight: '500px', height: 'auto', padding: '0px', width: '100%', minWidth: '200px'}}/>
            </Hidden>
          )}
          {props.imgs[1] && (
            <Hidden only={['xs']}>
              <div  className={classes.img} style={{ backgroundImage: 'url(' + props.imgs[1].file + ')', maxHeight: '500px', height: 'auto', padding: '0px', width: '100%', minWidth: '200px'}}/>
            </Hidden>
          )}
          {props.imgs[0] && (
            <div  className={classes.img} style={{ backgroundImage: 'url(' + props.imgs[0].file + ')', maxHeight: '500px', height: 'auto', padding: '0px', width: '100%', minWidth: '200px'}}/>
          )}

      </div>
  );
}

