import React, { useState } from "react";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core/styles";
import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSnackbar } from "material-ui-snackbar-provider";
import { useHistory } from "react-router-dom";
import { sleep } from "../utils";
import { CleverFormattedMessage } from "../Texts/CleverFormattedMessage";
import { useIntl } from "react-intl";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { JwtPayload, Nullable } from "../types";
import { loginDve } from "../api";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2)
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500]
    }
  });

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "25ch",
      backgroundColor: "white",
      padding: "20px"
    }
  },
  input: {
    width: "100%",
    margin: "10px 0px 10px 0px"
  },
  button: {
    marginLeft: "0px",
    color: theme.palette.primary.main
  },
  svg: {
    verticalAlign: "middle",
    fontSize: "2.5rem"
  },
  link: {
    color: "#FFFFFF",
    marginLeft: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginLeft: "5px"
    }
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center"
  },
  buttonForm: {
    color: "white"
  }
}));

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

interface LoginProps {
  open: boolean;
  handleLoginClose: () => void;
  redirectUrl?: string;
  forgottenPassword?: Nullable<JSX.Element>;
}

export function Login(props: LoginProps) {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const snackbar = useSnackbar();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const loginSuccess = formatMessage({ id: "login_success" });

  const onSubmit = async (event: React.FormEvent<HTMLElement>) => {
    event.preventDefault();
    props.handleLoginClose();
    let jwtPayload: JwtPayload;
    try {
      jwtPayload = await loginDve(login, password);
    } catch (e) {
      const invalidCredential = formatMessage({ id: "invalid_credential" });
      snackbar.showMessage(invalidCredential);
      return;
    }
    dispatch({ type: "SET_JWT_PAYLOAD", data: jwtPayload });
    snackbar.showMessage(loginSuccess);
    await sleep(500);
    if (props.redirectUrl) {
      history.push(props.redirectUrl);
    }
  };

  const labelUsername = formatMessage({ id: "login_username_label" });
  const labelPassword = formatMessage({ id: "login_password_label" });

  return (
    <Dialog
      onClose={props.handleLoginClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle
        id="customized-dialog-title"
        onClose={props.handleLoginClose}
      >
        <CleverFormattedMessage id="login_title" />
      </DialogTitle>
      <DialogContent dividers>
        <Grid container justify="center" className={classes.root}>
          <Grid item xs={12} md={12}>
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
              <TextField
                id="standard-basic"
                label={labelUsername}
                onChange={e => setLogin(e.target.value)}
                value={login}
                variant="outlined"
                className={classes.input}
              />
              <TextField
                id="standard-basic"
                label={labelPassword}
                onChange={e => setPassword(e.target.value)}
                type="password"
                value={password}
                variant="outlined"
                className={classes.input}
              />
              <Button
                type="submit"
                className={classes.buttonForm}
                color="primary"
                fullWidth
                variant="contained"
              >
                <CleverFormattedMessage id="login_button" />
              </Button>
              <Typography component="span" variant="body2">
                {props.forgottenPassword}
              </Typography>
            </form>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
