import React from "react";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import DescriptionIcon from "@material-ui/icons/Description";
import TimelineIcon from "@material-ui/icons/Timeline";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import VideoLibraryIcon from "@material-ui/icons/VideoLibrary";
import { Link } from "react-router-dom";
import { LazyCardMedia } from "../LazyCardMedia";
import { DurationFormat } from "../utils";

export interface SimpleCardProps {
  title: string;
  type: string;
  img?: string;
  category?: string;
  duration?: number;
  videos?: number;
  path?: string;
  diverse?: string;
  style?: any;
  lazy?: boolean;
}

//TODO: this SimpleCard should not include such if else if else if (...)
export function SimpleCard(props: SimpleCardProps) {
  const classes = useStyles(props.style || {})();

  return (
    <Card className={classes.card} raised={false}>
      <Link to={props.path ? props.path : "/"} className={classes.link}>
        <CardActionArea>
          <div
            className={(() => {
              if (props.duration) {
                return classes.svgPlayer;
              } else if (props.category) {
                return classes.svgIcon;
              } else if (props.videos) {
                return classes.svgCollections;
              }
              return;
            })()}
          >
            <LazyCardMedia lazy={props.lazy} title={props.title} component="img" image={props.img || ""} style={{...props.style}}/>
            {props.type === "pdf" && (
              <DescriptionIcon className={classes.icon} />
            )}
            {props.type === "graph" && (
              <TimelineIcon className={classes.icon} />
            )}
            {props.type === "video" && (
              <PlayCircleOutlineIcon className={classes.play} />
            )}
            {props.type === "collections" && (
              <VideoLibraryIcon className={classes.icon} />
            )}
          </div>
          <CardContent className={classes.content}>
            <Typography variant="h6" component="h6">
              {props.title}
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              component="p"
              className={classes.category}
            >
              {(() => {
                if (props.duration && props.category) {
                  return props.category + ' - ' + DurationFormat(props.duration);
                } else if (props.duration) {
                  return DurationFormat(props.duration);
                } else if (props.category) {
                  return props.category;
                } else if (props.videos) {
                  return props.videos + " videos";
                }
                return;
              })()}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}

const useStyles = (extendsStyle:any) => makeStyles((theme: Theme) =>
  createStyles({
    card: {
      boxShadow: "none",
      borderRadius: "0px"
    },
    content: {
      padding: "0px",
      marginTop: "10px"
    },
    svgIcon: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "left",
      "& svg": {
        opacity: 100,
        transition: "opacity 0.25s ease-in"
      }
    },
    svgPlayer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover": {
        "& svg": {
          opacity: 100,
          transition: "opacity 0.25s ease-in"
        }
      }
    },
    svgCollections: {
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "left",
      "& svg": {
        opacity: 100,
        transition: "opacity 0.25s ease-in"
      },
      "&:hover img": {
        transform: "scale(1.05)",
        transformOrigin: "50% 50%"
      },
      "& img": {
        transition: "transform .6s",
        [theme.breakpoints.down("sm")]: {
          height: "120px"
        }
      }
    },
    icon: {
      position: "absolute",
      color: "white",
      fontSize: "3.5rem",
      opacity: 0,
      padding: "8px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "2.3rem"
      }
    },
    category: {
      marginTop: "5px"
    },
    play: {
      position: "absolute",
      color: "white",
      fontSize: "5rem",
      opacity: 0
    },
    img: {
      maxWidth: "100%",
      position: "absolute",
      top: "0",
      maxHeight:"250px",
    },
    lazyHolder: {
      width: "100%",
      background: "#f8f8f8",
      position: "relative",
      paddingTop: "56.25%"
      },
    ...extendsStyle
  }
  )
);
