import { flatMap } from "lodash";
import slugify from "slug";
import { charmapSlugify } from "./charmapSlugify";
import { Nullable } from "./types";

export const sleep = (ms: number) => {
  return new Promise(resolve => {
    setTimeout(() => resolve(true), ms);
  });
};

export const randomElementId = (prefix = "") => {
  return prefix + String(Math.random()).replace(".", "");
};

//TODO: handle a cancel mode?
//TODO: set as deprecated?
export const setIntervalBetweenTooCallsToFunction = async (aFunction: Function,interval: number) => {
  while(true){
    try{
      await sleep(interval);
      await aFunction();
    }catch(e){
      console.log("Error while doing setIntervalBetweenTooCallsToFunction");
    }
  }
}

export const setIntervalBetweenTwoCallsToFunction = setIntervalBetweenTooCallsToFunction;

export const flexibleGetEnv = (key:string):Nullable<string> => {
  const windowAny = window as any;
  if(windowAny.windowEnv && windowAny.windowEnv[key]){
    return windowAny.windowEnv[key]
  };
  return process.env[key];
}

export function stripHtml(html:string)
{
   var tmp = document.createElement("DIV");
   tmp.innerHTML = html;
   return tmp.textContent || tmp.innerText || "";
}

export const DurationFormat = (duration: number, formatMessage?: any) => {

  let formatHour = "h";
  let formatMinute = "min";
  let formatSeconde = "sec";

  if(formatMessage){
    formatHour = formatMessage({id: 'format_hour'});
    formatMinute = formatMessage({id: 'format_minute'});
    formatSeconde = formatMessage({id: 'format_seconde'});
  }

  let d = Number(duration);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + formatHour + " " : "";
  var mDisplay = m > 0 ? m + formatMinute+ " " : "";
  var sDisplay = "";
  if (!hDisplay && !mDisplay){
    sDisplay = s > 0 ? s + formatSeconde + " " : "";
  }
  return hDisplay + mDisplay + sDisplay;
};


export const checkEmail = (email: string) => {
    let regEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(email)) {
        return false;
    }
    return true;
}

export const commonSlugify = (input: string) => {
  const res = flatMap(input.split(""),(aChar => charmapSlugify[aChar] ? charmapSlugify[aChar]  : aChar))
  const joined = res.join("");
  return slugify(joined.replace(/[^a-zA-Z0-9]/g,"-"));
}

//Object.fromEntries(new URLSearchParams(queryString).entries()) should work great ; but not well supported
export const parseQuery = (queryString: string) => {
  if(!queryString){
    return null;
  }
  let query: {[key: string]: string} = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');

  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
}

export const pushToLocalStorageCampaign = () => {
  let query = parseQuery(window.location.search);

  // We look if campaign exists
  let currentCampaign = localStorage.getItem('campaign');
  if (currentCampaign) {
    let parsedCampaign = JSON.parse(currentCampaign);

    // We check if the format contains time otherwise we don't process time check
    if (!parsedCampaign.time) {
      localStorage.removeItem('campaign');
    } else if (((Date.now() - parsedCampaign.time) / 1000) >= 15*60*60) {
      // We check if the campaign has more than 15 minutes // Maybe add time i don't know..
      localStorage.removeItem('campaign');
    }
  }

  if (!localStorage.getItem('campaign') && query) {
    localStorage.setItem('campaign', JSON.stringify({
      ...query,
      time: Date.now()
    }));
  }
}

export const getLangs = () => {
  let env = flexibleGetEnv("REACT_APP_LANGS");

  return (env || "").split(',');
}

export const getCurrentLang = () => {
  const allLanguages = getLangs();

  if (!localStorage.getItem('lang')) {
    const foundLang = allLanguages.find((e: string) => new RegExp(e).test(navigator.language));
    const langToSet = foundLang || allLanguages[0];
    localStorage.setItem('lang', langToSet);
  }

  let currentLang = localStorage.getItem('lang') as string;
  if (!allLanguages.includes(currentLang)) {
    currentLang = allLanguages[0];
  }
  return currentLang;
}
