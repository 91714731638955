import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import { CleverFormattedMessage } from '../Texts/CleverFormattedMessage';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'center',
      padding: '50px 0px 50px 0px',
      [theme.breakpoints.down('sm')]: {
        paddingLeft: '24px',
        paddingRight: '24px',
      },
    },
    text: {
      color: theme.palette.secondary.main,
    },
  }),
);

interface FullTextProps {
  title?: string;
  description?: string;
  color?: string;
}

export function FullText(props: FullTextProps) {
  const classes = useStyles();

  const getTextColorProperty = () => {
    let color = "black";
    if(props.color){
      color = props.color;
    }
    return color;
  }
  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={0}
      className={classes.root}
    >
      <Grid item md={6} xs={12}>
        {props.title && (
        <Typography component="h2" variant="h4" className={classes.text} style={{color: getTextColorProperty()}}>
          <CleverFormattedMessage id={props.title} />
        </Typography>
        )}
        {props.description && (
        <Typography component="p" className={classes.text} style={{color: getTextColorProperty()}}>
          <CleverFormattedMessage id={props.description} />
        </Typography>
        )}
      </Grid>
    </Grid>
  );
}
