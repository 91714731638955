import { checkType } from "./runtimeTypeChecker";
import PropTypes from "prop-types";
import {
  IText,
  IFile,
  State,
  AppState
} from "./types";
import { invert, zipObject } from "lodash";
import { getChannelToDisplay } from "./videokubeUtils";
import { getDefaultState } from "./defaultState";
import { createSelector } from "reselect";
export interface Action {
  type:
    | "SET_TEXTS"
    | "SET_INTERFACE_CONFIGURATION"
    | "SET_CATEGORIES"
    | "SET_CHANNELS"
    | "SET_TV_VIDEO_LINK"
    | "SET_FILES"
    | "SET_READY"
    | "SET_JWT_PAYLOAD"
    | "SET_CONNECTION_INFO"
    | "SET_EVENTS";
  data: any;
}

export { getDefaultState }

export const appRecucer = (
  state = getDefaultState(),
  action: Action
): State => {
  switch (action.type) {
    case "SET_TEXTS":
      return {
        ...state,
        ...{
          texts: action.data,
          textsDict: action.data ? zipObject(action.data.map((x: IText) => x.key),action.data) : {}
        }
      };
    case "SET_FILES":
      return {
        ...state,
        ...{
          files: action.data,
          filesDict: action.data ? zipObject(action.data.map((x: IFile) => x.name),action.data) : {}
        }
      };
    case "SET_INTERFACE_CONFIGURATION":
      return {
        ...state,
        ...{
          interfaceConfiguration: action.data
        }
      };
    case "SET_CATEGORIES":
      checkType(PropTypes.array, action.data);
      return {
        ...state,
        ...{
          categories: action.data
        }
      };
    case "SET_CHANNELS":
      return {
        ...state,
        ...{
          channels: action.data,
          mainChannel: getChannelToDisplay(action.data)
        }
      };
    case "SET_TV_VIDEO_LINK":
      return {
        ...state,
        ...{
          tvVideoLink: action.data
        }
      };
      case "SET_CONNECTION_INFO":
        return {
          ...state,
          ...{
            connectionInfo: action.data
          }
        };
    case "SET_READY":
      return {
        ...state,
        ...{
          ready: true
        }
      };
    case "SET_JWT_PAYLOAD":
      checkType(
        PropTypes.shape({
          iat: PropTypes.number.isRequired,
          exp: PropTypes.number.isRequired,
          roles: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
          username: PropTypes.string.isRequired
        }),
        action.data
      );
      return {
        ...state,
        ...{
          jwtPayload: action.data
        }
      };
    case "SET_EVENTS":
      return {
        ...state,
        ...{
          events: action.data
        }
      };
    default:
      return state;
  }
};

const getPayload = (state: AppState) => state.app.jwtPayload;

export const countrySelectorGen = (currencyFromCountry:Record<string,string>) => createSelector([getPayload], (jwtPayload) => {
  const countryFromCurrency = invert(currencyFromCountry);
  let currencyCode = jwtPayload?.user?.lastOrder?.currencyCode;
  let countryCode = '';
  if (currencyCode) {
    countryCode = countryFromCurrency[currencyCode];
  }
  return countryCode;
  }
);
