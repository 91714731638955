import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export interface ImageViewerProps {
  img: string;
  textAlt?: string;
}

export function ImageViewer(props: ImageViewerProps) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <img src={props.img} className={classes.img} alt={props.textAlt} />
    </div>
  );
}

const useStyles = makeStyles({
  root: {},
  img: {
    borderRadius: "5px"
  }
});
