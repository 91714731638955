import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { SeeAll } from "./SeeAll";
import { CleverFormattedMessage } from "./CleverFormattedMessage";

const defaultStyle = (theme: Theme) => {
  return createStyles({
    root: {
      width: "100%",
      margin: "40px 0px 5px 0px"
    },
    text: {
      textTransform: "uppercase",
      fontWeight: "bold",
      verticalAlign: 'sub',
      [theme.breakpoints.down("sm")]: {
        fontSize: "1.35rem"
      },
    },
    title: {
      display: 'inline !important'
    },
    subText: {
      marginLeft: '10px',
      fontSize: "14px",
      textTransform: "uppercase",
      letterSpacing: "1px",
      fontWeight: "bold",
      alignSelf: "flex-end",
    },
  });
};

type Style = ReturnType<typeof defaultStyle>;
type PartialStyle = Partial<Style>;

const useStyles = (extendsStyle: PartialStyle) => makeStyles((theme: Theme) => {
  const baseStyle = defaultStyle(theme);
    return createStyles({
      ...baseStyle,
      ...extendsStyle
    })
  }
);

SubTitle.defaultProps = {
  seeAllText: 'see_all'
};

export interface SubTitleProps {
  text: string;
  path?: string;
  border?: boolean;
  dontTranslate?: boolean;
  style?: PartialStyle;
  subText?: string;
  seeAllText?: string;
}

export function SubTitle(props: SubTitleProps) {
  let classes = useStyles(props.style || ({} as PartialStyle))();

  return (
    <div className={classes.root}>
      <Box display="flex" flexGrow={1}>
        <Box flexGrow={1}>
          <Typography style={{display: 'inline-block'}}
            component="h3"
            variant="h4"
            color="primary"
            className={classes.text}
          >
            {props.dontTranslate === undefined && (
              <CleverFormattedMessage className={classes.title} id={props.text} />
            )}

            {props.dontTranslate ? props.text : ""}

          </Typography>
          {props.subText &&
              <Typography style={{display: 'inline-block'}}
              color="primary"
              component="h3"
              variant="h4"
              className={classes.subText}
              >
                <div>{props.subText}</div>
              </Typography>
            }
        </Box>

        {props.path && <SeeAll path={props.path} textId={props.seeAllText} />}
      </Box>

      {props.border && <div className="border"></div>}
    </div>
  );
}
