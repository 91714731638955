import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import SchoolIcon from '@material-ui/icons/School';
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import ShowMoreText from "react-show-more-text";

export interface UniversityClassesProps {
  title: string;
  category: string;
  date: Date | string;
  link: string;
  source: string;
  description: string;
  cost?: string | null;
  coursesLevel?: string | null;
  coursesLength?: string | null;
  coursesType?: string | null;
  showMore?: boolean;
}

export function UniversityClasses(props: UniversityClassesProps) {
  const classes = useStyles();

  return (
    <div>
      <Box display={{ xs: 'none', md: 'block', lg: 'block', xl: 'block' }}>
        <Grid container className={classes.card}>
          <Grid item xs={12} md={2}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              className={classes.boxItem}
            >
              <CourseType coursesType={props.coursesType} classes={classes}/>
            </Box>
          </Grid>
          <CourseMainInfos classes={classes} title={props.title} description={props.description} date={props.date} category={props.category} link={props.link} source={props.source}/>
          <Grid container item md={3} xs={12} className={classes.info} direction="row" justify="center" alignItems="center">
            <Grid item xs={12} md={6}>
                <Box className={classes.firstBlock}>
                    <Typography variant="overline" display="block">COST</Typography>
                    <Typography variant="button" display="block">{props.cost}</Typography>
                </Box>

                <Box className={classes.secondBlock}>
                    <Typography variant="overline" display="block">STARTS</Typography>
                    <Typography variant="button" display="block">{props.date}</Typography>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box className={classes.firstBlock}>
                    <Typography variant="overline" display="block">LEVEL</Typography>
                    <Typography variant="button" display="block">{props.coursesLevel}</Typography>
                </Box>

                <Box className={classes.secondBlock}>
                    <Typography variant="overline" display="block">LENGTH</Typography>
                    <Typography variant="button" display="block">{props.coursesLength}</Typography>
                </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box display={{ xs: 'block', md: 'none', lg: 'none', xl: 'none' }}>
        <Grid container className={classes.card}>
          <CourseMainInfos classes={classes} title={props.title} description={props.description} date={props.date} category={props.category} link={props.link} source={props.source}/>
          <Grid container item md={3} xs={4} direction="row" justify="center" alignItems="center">
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="left"
              className={classes.info}
            >
              <CourseType coursesType={props.coursesType} classes={classes}/>

              <Box className={classes.block}>
                <Typography variant="overline" display="block">COST</Typography>
                <Typography variant="button" display="block" className={classes.infoValue}>{props.cost}</Typography>
              </Box>

              <Box className={classes.block}>
                <Typography variant="overline" display="block">STARTS</Typography>
                <Typography variant="button" display="block" className={classes.infoValue}>{props.date}</Typography>
              </Box>
              <Box className={classes.block}>
                <Typography variant="overline" display="block">LEVEL</Typography>
                <Typography variant="button" display="block" className={classes.infoValue}>{props.coursesLevel}</Typography>
              </Box>

              <Box className={classes.block}>
                <Typography variant="overline" display="block">LENGTH</Typography>
                <Typography variant="button" display="block" className={classes.infoValue}>{props.coursesLength}</Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

interface CourseTypeProps {
  coursesType?: string | null;
  classes: Record<string, string>;
}

const CourseType = (props: CourseTypeProps) => {
  return (
    <div>
      <Box display="flex">
        <SchoolIcon className={props.classes.svg} />
      </Box>
      <Box display="flex">
        <Typography
          variant="overline"
          component="p"
          className={props.classes.fileType}
        >
          {props.coursesType}
        </Typography>
      </Box>
    </div>
  )
}

interface CourseMainInfosProps {
  title: string;
  category: string;
  date: Date | string;
  link: string;
  source: string;
  description: string;
  showMore?: boolean;
  classes: Record<string, string>;
}

const CourseMainInfos = (props: CourseMainInfosProps) => {
  let classes = props.classes;
  return (
    <Grid item md={7} xs={8} className={classes.mainInfo}>
      <Box display="flex" mb={1}>
        <Box flexGrow={1}>
          <Typography
            color="textSecondary"
            className={classes.categoryDate}
            gutterBottom
          >
            {props.category}
          </Typography>
        </Box>
      </Box>
      <Typography component="h6" variant="h6" className={classes.title}>
        {props.title}
      </Typography>
      <Typography
        component="div"
        variant="caption"
        className={classes.description}
        gutterBottom
      >
        {props.showMore && (
            <ShowMoreText
              lines={1}
              width={1300}
              more="Read description"
              less="Less"
              anchorClass="MuiTypography-subtitle1"
            >
                <span>{props.description}</span>
            </ShowMoreText>
        )}
        {!props.showMore && <div><span>{props.description}</span></div>}
      </Typography>
      <Typography
        component="p"
        variant="subtitle1"
        className={classes.source}
        gutterBottom
      >
        LEARN MORE
      </Typography>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  firstBlock: {
    marginBottom: '10px'
  }, 
  secondBlock: {
    marginTop: '10px'
  },
  block: {
    marginTop: '10px'
  },
  infoValue: {
    fontSize: "0.6rem"
  },
  card: {
    borderRadius: "3px",
    border: "1px solid #9b9b9b",
    padding: "10px"
  },
  boxItem: {
    height: "100%"
  },
  svg: {
    fontSize: "4rem",
    color: "#cccccc",
    [theme.breakpoints.down('md')]: {
      fontSize: "2rem"
    }
  },
  fileType: {
    fontWeight: "bold"
  },
  categoryDate: {
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "0.7em",
    color: "#9B9B9B",
    letterSpacing: "1px"
  },
  title: {
    fontSize: "1rem",
    minHeight: "30px",
    overflow: 'hidden',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%'
  },
  description: {
    lineHeight: "13px",
    color: "#999999",
    opacity: "0.9",
    fontSize: "0.7em"
  },
  source: {
    textTransform: "uppercase",
    fontSize: "0.6em",
    fontWeight: "bold",
    letterSpacing: "0.45px",
    marginTop: "10px"
  },
  info: {
    borderLeft: '2px solid silver',
    padding: '0px 10px',
    float: 'left'
  },
  mainInfo: {
    paddingRight: '10px'
  },
}));
