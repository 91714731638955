import { SnackbarProviderValue } from "material-ui-snackbar-provider/lib/SnackbarContext";
import { useDispatch } from "react-redux";
import {
  JwtPayload,
  OrderInfo,
  SubscribeResult,
  SubscriptionInfo,
  UserInfo
} from "./types";
import {
  logout as apiLogout,
  unsubBillingSubscription,
  getUserDetails,
  getBillingPlans,
  subscribeAsLoggedIn,
  subscribeAsNotLoggedIn,
  billingLogin,
  countryFromIp,
  forgetPassword as apiForgetPassword,
  loginWithHE
} from "./api";
import { useHistory } from "react-router-dom";
import { sleep } from "./utils";
import moment from "moment";

type DispatchType = ReturnType<typeof useDispatch>;
// type formatMessage = {
//   (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType> | undefined): string;
//   (descriptor: MessageDescriptor, values?: Record<...> | undefined): string | ReactNodeArray;
// }

export const wrapperObject = (
  dispatch: DispatchType,
  jwtPayload: JwtPayload,
  snackbar: SnackbarProviderValue,
  formatMessage: any
) => {
  const history = useHistory();
  // User info
  let userInfo: UserInfo;
  let subscription: SubscriptionInfo;
  let lastOrder: OrderInfo;

  // Load userInfo
  if (jwtPayload && jwtPayload.user) {
    userInfo = jwtPayload.user;

    if (userInfo.lastSubscription) {
      subscription = userInfo.lastSubscription;
      if(subscription.status === 'PARKING'){
        snackbar.showMessage("subscription_in_parking");
      }
    }
    if (userInfo.lastOrder) {
      lastOrder = userInfo.lastOrder;
    }
  }

  const forgetPassword = async (username: string, captcha: string) => {
    const passwordMailSendSuccess = formatMessage({
      id: "password_mail_send_success"
    });
    // const passwordMailSendError = formatMessage({ id: 'password_mail_send_error' });
    try {
      await apiForgetPassword(username, captcha).then(async () => {
        snackbar.showMessage(passwordMailSendSuccess);
      });
    } catch (e) {
      snackbar.showMessage(formatMessage({ id: e.response.data.message }));
    }
  };

  const logout = () => {
    const logoutLabel = formatMessage({ id: "logout_label" });
    snackbar.showMessage(logoutLabel);
    apiLogout();
    history.push("/");
    return dispatch({ type: "SET_JWT_PAYLOAD", data: null });
  };

  const unsubscribe = async () => {
    const unsubcribeSuccess = formatMessage({ id: "unsubcribe_success" });
    const unsubcribeWaiting = formatMessage({ id: "unsubcribe_waiting" });
    snackbar.showMessage(unsubcribeWaiting);
    const subscriptionIud = getSubscriptionIud();
    if (subscriptionIud) {
      try {
        await unsubBillingSubscription(subscriptionIud).then(async () => {
          snackbar.showMessage(unsubcribeSuccess);
        });
      } catch (e) {
        snackbar.showMessage(e.response.data.error);
      }
    }
    await reloadJwtPlayload();
    await sleep(2000);
  };

  const redirectTosubscribe = () => {
    history.push("/subscribe");
  };

  const login = async (username: string, password: string) => {
    let jwtPayload: JwtPayload;
    try {
      jwtPayload = await billingLogin({ username, password });
    } catch (e) {
      snackbar.showMessage(formatMessage({ id: "invalid_credential" }));
      return;
    }
    dispatch({ type: "SET_JWT_PAYLOAD", data: jwtPayload });
    snackbar.showMessage(formatMessage({ id: "login_success" }));
    // console.log("jwtPayload");
    // console.log(jwtPayload);
    if (
      window.location.pathname === "/login" ||
      window.location.pathname === "/subscribe" ||
      window.location.pathname === "/thank-you"
    ) {
      history.push("/");
    }
  };

  const subscribe = async (
    planUid: string,
    username?: string,
    password: string | undefined = undefined,
    marketing: null | { [key: string]: string } = null,
    email: string | undefined = undefined
  ) => {
    // On bouge les user actif
    if (getIsActiveUser()) {
      userIsActive();
      return null;
    }

    let subscribeResult: SubscribeResult;
    // On ne peut pas identifier l'user
    if (!username && !getUsername()) {
      const mandatoryParamNeed = formatMessage({ id: "mandatory_param_need" });
      snackbar.showMessage(mandatoryParamNeed);
      return null;
    }
    // On arrive sans l'username
    // if (!username) {
    //   username = getUsername();
    // }

    if (getUsername()) {
      //username = getUsername();
      try {
        subscribeResult = await subscribeAsLoggedIn(planUid, marketing);
      } catch (e) {
        // Todo Y a besoin de normailiser le code d'erreur ou le texte
        if (e === "EXCEPTION_SUBSCRIPTION_FOUND") {
          await reloadJwtPlayload();
        }

        snackbar.showMessage(formatMessage({ id: e }));
        return null;
      }
      // console.log(subscribeResult);
      return subscribeResult;
    }

    if (username) {
      try {
        subscribeResult = await subscribeAsNotLoggedIn(
          planUid,
          { username, password, email },
          marketing
        );
      } catch (e) {
        // Todo Y a besoin de normailiser le code d'erreur ou le texte
        if (e === "EXCEPTION_SUBSCRIPTION_FOUND" && password) {
          await login(username, password);
        }

        snackbar.showMessage(formatMessage({ id: e }));
        return null;
      }
      return subscribeResult;
    }
    return null;
  };

  // TODO may be multiple plans
  const getPlans = async (username?: string, currencyCode?: string) => {
    const billingPlanNotFound = formatMessage({ id: "billing_plan_not_found" });
    try {
      const billingPlans = await getBillingPlans(username, currencyCode);
      return billingPlans;
    } catch (e) {
      snackbar.showMessage(billingPlanNotFound);
      return null;
    }
  };

  const reloadJwtPlayload = async () => {
    // Reload
    const jwtPayload = await getUserDetails();
    dispatch({ type: "SET_JWT_PAYLOAD", data: jwtPayload });
  };

  const autologinHE = async (username: string) => {
    // Reload
    const jwtPayload = await loginWithHE(username);
    dispatch({ type: "SET_JWT_PAYLOAD", data: jwtPayload });
  };

  const getSubscriptionStatus = () => {
    return subscription?.status;
  };

  const getLastOrderStatus = () => {
    return lastOrder?.status;
  };

  const getIsActiveUser = () => {
    return !!userInfo?.activeSubscription;
  };

  const getSubscriptionIud = () => {
    return subscription?.uid;
  };

  const getExternalId = () => {
    return subscription?.externalId;
  };

  const getSubscriptionExpirationDate = () => {
    if (!subscription) {
      return;
    }
    return moment(subscription.expirationDate).format('YYYY-MM-DD');
  };

  const getSubscriptionCancellationDate = () => {
    if (!subscription) {
      return;
    }
    if (!subscription.cancellationDate) {
      return;
    }
    return moment(subscription.cancellationDate).format('YYYY-MM-DD');
  };

  const getUsername = () => {
    return userInfo?.username;
  };

  const getLabelSubscription = () => {
    const subscriptionActive = formatMessage({ id: "subscription_active" });
    const subscriptionNotActive = formatMessage({
      id: "subscription_not_active"
    });
    return getIsActiveUser() ? subscriptionActive : subscriptionNotActive;
  };

  const userIsActive = () => {
    const userIsActive = formatMessage({ id: "user_is_active" });
    history.push("/");
    snackbar.showMessage(userIsActive);
  };

  const getCountry = async () => {
    const noCountryFoundForIP = formatMessage({
      id: "no_country_found_for_ip"
    });
    try {
      const countryGeoIP = await countryFromIp();
      return countryGeoIP.countryCode;
    } catch (e) {
      return snackbar.showMessage(noCountryFoundForIP);
    }
  };



  // TODO: rename getSubscriptionIud in Uid ??
  return {
    // Pour comprendre mais en gros on devrait pas faire
    // logout: logout mais simplement logout
    logout: logout,
    unsubscribe: unsubscribe,
    redirectTosubscribe: redirectTosubscribe,
    subscribe: subscribe,
    login: login,
    forgetPassword: forgetPassword,

    getUsername: getUsername,
    getPlans: getPlans,
    userIsActive: userIsActive,
    getIsActiveUser: getIsActiveUser,
    getSubscriptionIud: getSubscriptionIud,
    getExternalId: getExternalId,
    getSubscriptionExpirationDate: getSubscriptionExpirationDate,
    getSubscriptionCancellationDate: getSubscriptionCancellationDate,
    getLabelSubscription: getLabelSubscription,
    reloadJwtPlayload: reloadJwtPlayload,
    autologinHE: autologinHE,
    getSubscriptionStatus: getSubscriptionStatus,
    getLastOrderStatus: getLastOrderStatus,
    getCountry: getCountry
  };
};
