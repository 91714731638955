import { IText, IFile, InterfaceConfiguration, Category, Media, VideoLink, JwtPayload, IEvent, ConnectionInfo } from "./types";

export const getDefaultState = () => ({
  texts: [] as IText[],
  textsDict: {} as Record<string, IText>,
  files: [] as IFile[],
  filesDict: {} as Record<string, IFile>,
  interfaceConfiguration: (null as unknown) as InterfaceConfiguration,
  categories: [] as Category[],
  channels: [] as Media[],
  mainChannel: null as Media | null,
  tvVideoLink: (null as unknown) as VideoLink,
  ready: false,
  connectionInfo: {} as ConnectionInfo,
  jwtPayload: (null as unknown) as JwtPayload,
  events: [] as IEvent[]
});
