import React from "react";
import LazyLoad from 'react-lazy-load';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";

export interface LazyCardMediaProps {
    component: any;
    title?: string;
    image: string;
    style?: any;
    lazy?: boolean;
}

const _loaded = {};

export function LazyCardMedia(props: LazyCardMediaProps) {
    const classes = useStyles(props.style || {})();
    const [loaded, setLoaded] = React.useState(_loaded[props.image]);
    let lazy = props.lazy === undefined? true : props.lazy ;

    const onLoad = () => {
        _loaded[props.image] = true;
        setLoaded(true);
    };

    const renderCardMediaOnLoad = () => (
        <CardMedia
            component={props.component}
            alt={loaded ? props.title : undefined}
            image={props.image}
            title={loaded ? props.title : undefined}
            className={`${loaded ? classes.imgLoaded + " " + classes.img: classes.imgLoading}`}
            onLoad={onLoad}
        />
    );

    return (
        <React.Fragment>
            {lazy && (
                <LazyLoad debounce={false} verticalOffset={100} once className={classes.lazyHolder}>
                    {renderCardMediaOnLoad()}
                </LazyLoad>
            )}
            {!lazy && (
                <div className={classes.lazyHolder}>
                    {renderCardMediaOnLoad()}
                </div>
            )}
        </React.Fragment>
    )
}

const useStyles = (extendsStyle:any) => makeStyles(() =>
  createStyles({
    imgLoading: {
        opacity: "0",
    },
    imgLoaded: {
        animation: `$fadeInImg cubic-bezier(0.23, 1, 0.32, 1) 1`,
        opacity: "0",
        animationFillMode: "forwards",
        animationDuration: "0.25s",
    },
    "@keyframes fadeInImg": {
        "0%": {
          opacity: 0,
        },
        "100%": {
          opacity: 1,
        }
    },
    ...extendsStyle
  }
  )
);