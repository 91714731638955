import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import ShowMoreText from "react-show-more-text";
import Hidden from "@material-ui/core/Hidden";
import { CleverFormattedMessage } from "../Texts/CleverFormattedMessage";
import { Keyword, Metadata, Nullable } from "../types";
import { Link } from "react-router-dom";
import { Chip, Box, Button } from "@material-ui/core";
import { LazyCardMedia } from "../LazyCardMedia";
import { DurationFormat } from "../utils";
//import Rating from '@material-ui/lab/Rating';
import { useIntl } from "react-intl";

export interface VideoInformationProps {
  title?: string;
  duration?: number;
  productionYear?: number;
  language?: string;
  img: string;
  description?: string;
  keywords?: Keyword[];
  startAt?: Date;
  showMore?: boolean;
  style?: any;
  titlePlaying? : string;
  providerName?: Nullable<string>;
  providerNameSlug?: Nullable<string>;
  metadatas?: Metadata[];
  rating?: Nullable<number>;
}

export function VideoInformation(props: VideoInformationProps) {
  let classes = useStyles({})();
  if(props.style){
    classes = useStyles(props.style)();
  }

  const [displayedInfo, setDisplayedInfo] = useState(false);

  let artists = props.metadatas?.find(x => x.name === "artist");
  let artistsArray = [];
  if(artists){
    artistsArray = JSON.parse(artists.value);
  }
  let directors = props.metadatas?.find(x => x.name === "director");
  let directorsArray = [];
  if(directors){
    directorsArray = JSON.parse(directors.value);
  }
  const { formatMessage } = useIntl();

  return (
    <Grid container className={classes.root}>
      {/* Looks not appropriate. Should probably not be here. */}
      {props.startAt && (
        <Grid container item xs={12} className={classes.titleDate}>
          <Grid container item xs={5} md={3}>
            <Typography
              variant="subtitle1"
              component="p"
              className={classes.time}
              gutterBottom
            >
              {moment(props.startAt)
                .format("HH:mm")
                .toString()}
            </Typography>
          </Grid>
          <Grid container item xs={7} md={9} className={classes.columnTwo}>
            <Typography variant="subtitle1" component="div" gutterBottom>
              {props.titlePlaying && (
                <CleverFormattedMessage id={props.titlePlaying} />
              )}
              {!props.titlePlaying && (moment(props.startAt).unix() <= moment(new Date()).unix() ? (
                  <CleverFormattedMessage id="now_playing" />
                ) : (
                  <CleverFormattedMessage id="coming_up" />
                )
              )}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container item direction="column" md={3} className={classes.videoInformationImg}>
        <LazyCardMedia title={props.title} component="img" image={props.img} style={{...cardMediaStyles, ...props.style}}/>
      </Grid>
      <Grid container item md={9} direction="column" className={classes.columnTwo}>
        {props.title && (
          <Typography variant="h6" component="h6" className={classes.title}>
            {props.title}
          </Typography>
        )}
        <Hidden smDown>
          <VideoDuration duration={props.duration}/>
          
          <VideoKeywords keywords={props.keywords} classes={classes} size="medium"/>
          
          <VideoMedia productionYear={props.productionYear} language={props.language}/>

          <VideoDescription description={props.description} showMore={props.showMore} classes={classes}/>

          <VideoProvider providerName={props.providerName} providerNameSlug={props.providerNameSlug} classes={classes}/>
        
          <VideoListMetas metas={directorsArray} classes={classes} prefix="director" />

          <VideoListMetas metas={artistsArray} classes={classes} prefix="artist" />

          <VideoRating rating={props.rating} classes={classes}/>

        </Hidden>

        <Box display={{ sm: 'block', md: 'none', lg: 'none', xl: 'none' }}>
          {!displayedInfo ? (
            <Button variant="outlined" color="primary" size="small" className={classes.topSpacer} onClick={() => setDisplayedInfo(true)}>
              {formatMessage({id: "read_more"})}
            </Button>
          ) : (
            <div>
              <VideoDuration duration={props.duration}/>

              <VideoMedia productionYear={props.productionYear} language={props.language}/>

              <VideoRating rating={props.rating} classes={classes}/>
            </div>
          )}
        </Box>
      </Grid>

      {displayedInfo && (
        <Box display={{ md: 'none', lg: 'none', xl: 'none' }}>
          <Grid container item direction="column" xs={12} md={12}>
            <VideoKeywords keywords={props.keywords} classes={classes} size="small"/>

            <VideoDescription description={props.description} showMore={props.showMore} classes={classes}/>

            <VideoProvider providerName={props.providerName} providerNameSlug={props.providerNameSlug} classes={classes}/>

            <VideoListMetas metas={directorsArray} classes={classes} prefix="director" />

            <VideoListMetas metas={artistsArray} classes={classes} prefix="artist" />
          </Grid>
        </Box>
      )}
    </Grid>
  );
}

interface VideoDescriptionProps {
  description?: string;
  showMore?: boolean;
  classes: Record<string, string>;
};

const VideoDescription = (props: VideoDescriptionProps) => {
  let classes = props.classes;

  return (
    <React.Fragment>
    {props.description && (
      <React.Fragment>
      <Typography
        component={"span"}
        variant="body2"
        className={classes.description}
      >
        {props.showMore && (
          <ShowMoreText
            lines={1}
            width={1300}
            more="Read description"
            less="Less"
            anchorClass="MuiTypography-subtitle1"
          >
            <span>{props.description}</span>
          </ShowMoreText>
        )}
        {!props.showMore && <div dangerouslySetInnerHTML={{ __html: props.description }} />}
      </Typography>

    </React.Fragment>
    )}
    </React.Fragment>
  );
};

interface VideoMetaProps {
  productionYear?: number;
  language?: string;
}

const VideoMedia = (props: VideoMetaProps) => {
  return (
    <Typography variant="body1">
      {props.productionYear && (
        props.productionYear
          .toString()
          + ". "
          )}
          {props.language && (
            props.language
          )}
    </Typography>
  )
};

interface VideoKeywordsProps {
  keywords?: Keyword[];
  classes: Record<string, string>;
  size: "small" | "medium";
}


const VideoKeywords = (props: VideoKeywordsProps) => {
  let classes = props.classes;

  return (
    <React.Fragment>
      {props.keywords && props.keywords.length > 0 && (
        <Grid direction="row" className={classes.keywords}>
          {props.keywords.map(aKeyword => (
            <Link to={`/keyword/${aKeyword.name}`} key={aKeyword.name}>
              <Chip size={props.size} onClick={() => true} className={classes.topSpacer} label={"#" + aKeyword.name} />{" "}
            </Link>
          ))}
        </Grid>
      )}
    </React.Fragment>
  )
}

interface VideoProviderProps {
  providerName?: Nullable<string>;
  providerNameSlug?: Nullable<string>;
  classes: Record<string, string>;
}

const VideoProvider = (props: VideoProviderProps) => {
  let classes = props.classes;

  return (
    <React.Fragment>
      {props.providerNameSlug && (<Link to={`/provider/${props.providerNameSlug}`}>
          <Chip onClick={() => true} className={classes.topSpacer} label={props.providerName} color="primary" />
        </Link>)
      }
    </React.Fragment>
  )
}

interface VideoDurationProps {
  duration?: number;
}

const VideoDuration = (props: VideoDurationProps) => {
  const { formatMessage } = useIntl();
  return (
    <React.Fragment>
      {props.duration && (
        <Typography variant="subtitle1" component="p" gutterBottom>
          {DurationFormat(props.duration, formatMessage)}
        </Typography>
      )}
    </React.Fragment>
  )
}

interface VideoRatingProps {
  rating?: Nullable<number>;
  classes: Record<string, string>;
}

const VideoRating = (props: VideoRatingProps) => {
  let classes = props.classes;
  const { formatMessage } = useIntl();
  return (
    <React.Fragment>
      {props.rating && (
        <Grid container className={classes.topSpacer}>
          <Grid item className={classes.divImdbRate}>
            <span className={classes.imdbRate} >
              {formatMessage({ id: "imdb_rate" })}
            </span>
            <span className={classes.imdbRate}>
              {props.rating}
            </span>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  )
}

interface VideoListMetasProps {
  metas?: string[];
  classes: Record<string, string>;
  prefix: string;
}

const VideoListMetas = (props: VideoListMetasProps) => {
  let classes = props.classes;
  const { formatMessage } = useIntl();
  return (
    <React.Fragment>
      {props.metas && props.metas.length > 0 && (
        <Grid container>
          <Grid item >
            <Typography variant="subtitle1" component="p" className={classes.metaTitleText}>
              <strong>{formatMessage({ id: props.prefix + "_text" })}</strong>
            </Typography>
          </Grid>
          {props.metas.map(aMeta => (
            <Link to={`/${props.prefix}/${aMeta}`} key={aMeta}>
              <Typography variant="subtitle1" component="p" className={classes.metaText}>{aMeta}</Typography>
            </Link>
          ))}
        </Grid>
      )}
    </React.Fragment>
  )
}

const cardMediaStyles = {
  img: {
    maxWidth: "100%",
    position: "absolute",
    top: "0",
    borderRadius: "5px",
    maxHeight: "100%"
  },
  lazyHolder: {
    width: "100%",
    background: "#f8f8f8",
    position: "relative",
    paddingTop: "56.25%",
    alignSelf: "start"
  }
}

const useStyles = (extendsStyle:any) => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      margin: "auto",
      boxShadow: "none"
    },
    title: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8em"
      }
    },
    topSpacer: {
      marginTop: "5px"
    },
    keywords: {
      margin: "10px 0px"
    },
    imdbRate: {
      paddingLeft: '5px',
    },
    divImdbRate: {
      paddingTop: '5px',
      fontSize: '13px',
      borderRadius: '5px',
      padding: '3px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.dark,
    },
    description: {
      color: theme.palette.primary.main,
      lineHeight: "16px"
    },
    time: {
      fontWeight: "bold"
    },
    titleDate:{
    },
    videoInformationImg:{
      [theme.breakpoints.down("sm")]: {
        maxWidth: "50%",
      }
    },
    columnTwo: {
      paddingInlineStart: "20px",
      [theme.breakpoints.down("sm")]: {
        paddingInlineStart: "10px",
        maxWidth: "50%",
        //display: "table",
        "& p": {
          fontSize: "0.6em"
        }
      }
    },
    metaText: {
      color: theme.palette.primary.main,
      paddingRight: '7px',
      fontWeight: 'bold',
      textDecoration: 'underline',
    },
    metaTitleText:{
      paddingRight: '7px',
    },
    ...extendsStyle,
  })
);
