import PropTypes, { Validator } from "prop-types";

const getRandomComponentName = () =>
  "TypeChecker_" + String(Math.random()).replace(".", "");

const localCheckPropTypes = (
  aPropType: Validator<any>,
  aValueToCheck: any,
  getStack?: () => any
) => {
  //We generate a strange unique component name. Because if we always keep the same, we won't generate twice the same error. This is the default behaviour of prop types.
  const componentName = getRandomComponentName();
  PropTypes.checkPropTypes(
    { base: aPropType },
    { base: aValueToCheck },
    "base",
    componentName,
    getStack
  );
};

export const checkTypeWithoutConsoleError = (
  aPropType: Validator<any>,
  aValueToCheck: any
) => {
  localCheckPropTypes(aPropType, aValueToCheck, () => {
    throw new Error("Check has failed");
  });
};

export const checkType = (aPropType: Validator<any>, aValueToCheck: any) => {
  try {
    checkTypeWithoutConsoleError(aPropType, aValueToCheck);
  } catch (e) {
    localCheckPropTypes(aPropType, aValueToCheck);
    throw new Error("Check has failed");
  }
};
