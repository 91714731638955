import React, { useEffect, useState, useRef } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CleverFormattedMessage } from "../Texts/CleverFormattedMessage";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column"
      }
    },
    counter: {
      color: "#FFFFFF",
      marginBottom: "20px",
      textTransform: "uppercase"
    },
    times: {
      display: "flex",
      flexDirection: "row",
      fontSize: "2.8rem"
    },
    time: {
      width: "25%",
      minWidth: "80px",
      marginBottom: "20px"
    },
    infos: {
      display: "flex",
      flexDirection: "row"
    },
    info: {
      width: "25%"
    }
  })
);

interface CounterProps {
  date?: Date;
}

export function Counter(props: CounterProps) {
  const classes = useStyles();
  let [currentMoment, setCurrentMoment] = useState(moment());

  useInterval(() => {
    setCurrentMoment(moment());
  }, 1000);

  let duration = moment.duration(moment(props.date).diff(currentMoment));

  return (
    <div className={classes.counter}>
      <div className={classes.times}>
        <div className={classes.time}>
          {Math.floor(duration.asDays())}
        </div>
        <div className={classes.time}>
          {duration.get("hours")}
        </div>
        <div className={classes.time}>
          {duration.get("minutes")}
        </div>
        <div className={classes.time}>
          {duration.get("seconds")}
        </div>
      </div>
      <div className={classes.infos}>
        <div className={classes.info}>
          <CleverFormattedMessage id="days" />
        </div>
        <div className={classes.info}>
          <CleverFormattedMessage id="hours" />
        </div>
        <div className={classes.info}>
          <CleverFormattedMessage id="minutes" />
        </div>
        <div className={classes.info}>
          <CleverFormattedMessage id="seconds" />
        </div>
      </div>
    </div>
  );
}

function useInterval(callback: Function, delay: number) {
  const savedCallback = useRef() as any;

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
    return undefined;
  }, [delay]);
}
