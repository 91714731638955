import axios, { Method, AxiosRequestConfig } from "axios";
import { memoize } from "lodash";
import {
  Category,
  IText,
  Media,
  InterfaceConfiguration,
  JwtPayload,
  VideoLink,
  IFile,
  IScientificPaper,
  University, StandaloneResponse, IRegister, JwtPayloadAndJwtToken, IEvent, Nullable, BillingPlan, SubscribeResult, GeoPayload, LoginType
} from "./types";
import {flexibleGetEnv, getLangs} from "./utils";
import { feedMetaDatas } from "./videokubeUtils";

//We prefer to recalculate it each time in case it changes (if we receive the .js later or something else)
const getPrefix = () => {
  return flexibleGetEnv("REACT_APP_API_ENTRYPOINT");
};

const getApiPrefix = () => {
  return getPrefix() + "/api";
};

const apiFetch = async <T>(
  suffix: string,
  method:Method = 'GET',
  data: object | null | string = null,
  fullUrl: string = "",
  getParams: object | null = null
) => {
  const url = fullUrl || (getApiPrefix() + suffix);
  const options: AxiosRequestConfig = {
    headers: {
      Accept: 'application/json',
    },
    method,
    withCredentials: true,
    url
  };
  //TODO: videokubeBearerToken should be a const somewhere?
  const tokenInLocalStorage = localStorage.getItem("videokubeBearerToken");
  if(tokenInLocalStorage){
    const [,corePayload,] = tokenInLocalStorage.split(".");
    const jsonString = atob(corePayload || "");
    if(jsonString){
      try{
        const jsonObject = JSON.parse(jsonString);
        const now = Date.now() / 1000;
        //TODO: We could change the 0 with something else positive to make the token expire a little before its real expiration.
        //TODO: We should update the cookie regularly! Nobody has to reconnect if it uses the app everyday!
        if(jsonObject && jsonObject.exp && ((jsonObject.exp - 0) >= now)){
          options.headers.Authorization = `Bearer ${tokenInLocalStorage}`;
        }
      }catch(e){
      }
    }
  }

  const sid = flexibleGetEnv("REACT_APP_SID");
  if(sid){
    options.headers.SID = sid;
  }
  const lang = localStorage.getItem("lang") || getLangs()[0];
  if(lang){
    options.headers['accept-language'] = lang;
  }

  if(data){
    options.data = data;
  }

  if(getParams){
    options.params = getParams;
  }

  // const resultRaw = await axios(options);

  let resultRaw: any;
  try{
    resultRaw = await axios(options);
  } catch(exception){
    throw exception;
  }

  return resultRaw.data as T;
};

const getCategories = async (lightMode=false, params:object | null=null) => {
  let getParams = {};
  if(lightMode){
    getParams["fieldGroups"] = "categoryLight";
  }
  if(params){
    getParams = {...getParams,...params};
  }
  const categories = await apiFetch<Category[]>('/categories',"GET", null, "", getParams);
  categories.forEach(c => {
    c.medias = c.medias || [];
  })
  return categories;
};

const getUniversities = async () => {
  return await apiFetch<University[]>('/universities');
};

const getScientificPapersRandom = async () => {
  let slug = 'the-changing-chemistry-of-our-oceans';
  return await apiFetch<IScientificPaper[]>('/APICore?media.slug=' + slug);
};

const getScientificPapersWithMedia = async (slug: string) => {
  return await apiFetch<IScientificPaper[]>('/APICore?media.slug=' + slug);
};

const getScientificPapersWithCategory = async (slug: string) => {
  return await apiFetch<IScientificPaper[]>('/APICore?category.slug=' + slug);
};

const getScientificPapersWithKeyword = async (slug: string, page?: number) => {
  if (!page){
    page =1;
  }
  return await apiFetch<IScientificPaper[]>('/APICore?keyword.slug=' + slug + '&page=' + page);
};


const getScientificPaperWithId = async (id: number) => {
  return await apiFetch<IScientificPaper>('/a_p_i_cores/' + id);
};

const getScientificPapersWithMediaSlug = async (slug: string) => {
  return await apiFetch<IScientificPaper[]>('/APICore?media.slug=' + slug);
};

const getScientificPapersWithCategorySlug = async (slug: string) => {
  return await apiFetch<IScientificPaper[]>('/APICore?category.slug=' + slug);
};

const getTexts = async () => {
  return await apiFetch<IText[]>('/texts');
};

const getFiles = async () => {
  return await apiFetch<IFile[]>('/files');
};

const getChannel = async () => {
  return await apiFetch<Media[]>('/medias?live=1&limit=1');
};

const getMediasCore = async (params?:Nullable<object>) => {
  const medias = await apiFetch<Media[]>('/medias',"GET",null,"",params);

  return medias.map(feedMetaDatas);
};

//TODO: improve with sort keys!
const getMedias = memoize(getMediasCore,(params?:Nullable<object>) => {
  return JSON.stringify(params);
});

const getIdWithSlug = async (slug: string) => {
  const medias = await apiFetch<Media[]>('/medias?slug=' + slug);

  return medias.map(feedMetaDatas);
};

const getChannels = async () => {
  return await apiFetch<Media[]>('/medias?live=1');
};

const getVideoLink = async (id: number) => {
  return await apiFetch<VideoLink>('/video_links/' + id);
};

const getInterfaceConfiguration = async () => {
  return await apiFetch<InterfaceConfiguration>('/interface_configurations');
};

//TODO: we should store the url without the /api in REACT_APP_API_ENTRYPOINT and suffix ourselves with API when needed (...)
const loginDve = async (username: string, password: string) => {
  const bodyToStringify = {
    username,
    password,
  };
  const appropriateUrl = getPrefix() + '/login_dve';
  const {payload,token} = await apiFetch<JwtPayloadAndJwtToken>("","POST",bodyToStringify,appropriateUrl);
  localStorage.setItem("videokubeBearerToken", token);
  return payload;
};

const authTokenLogin = async (authToken: string) => {
  const appropriateUrl = getPrefix() + '/subscribe?authtoken=' + authToken;
  const { payload,token } = await apiFetch<JwtPayloadAndJwtToken>("","GET",null,appropriateUrl);
  localStorage.setItem("videokubeBearerToken", token);
  return payload;
};

//TODO: refactor
const pingMe = async () => {
  const appropriateUrl = getPrefix() + '/me';
  const resultRaw = await apiFetch<JwtPayload>("","GET",null,appropriateUrl);
  return resultRaw;
};

const sendMail = async (body: string, name: string, sender: string, captcha: string) => {
  const appropriateUrl = getPrefix() + '/mailing';

  return await apiFetch<StandaloneResponse>("", "POST", {
    name: name, body: body, captcha: captcha, sender: sender
  }, appropriateUrl);
};

const getPayloadFromToken = (token: string) => {
  const [, middle] = token.split('.');
  const jwtPayload = JSON.parse(atob(middle)) as JwtPayload;
  return jwtPayload;
};

const getEvents = async () => {
  return await apiFetch<IEvent[]>('/events');
};

const sendRegister = async (register: IRegister) => {
  const appropriateUrl = getPrefix() + '/register';

  return await apiFetch<StandaloneResponse>('', 'POST', register, appropriateUrl)
}

const confirmRegister = async (uuid: string) => {
  const appropriateUrl = getPrefix() + `/register/${uuid}`;

  return await apiFetch<StandaloneResponse>('', 'GET', null, appropriateUrl)
}

const getBillingPlans = async (username?: string | null, currencyCode?: string) => {
  let currency = currencyCode || null;

  const fullBody: {currency: string | null, username?:string} = {
    currency
  };
  if (username) {
    fullBody.username = username;
  }
  const appropriateUrl = getPrefix() + '/billing/appropriatePlans';
  const result = await apiFetch<BillingPlan[]>("", "POST", fullBody, appropriateUrl);
  return result;
};

const subscribeAsNotLoggedIn = async (planUid: string, loginType: LoginType,
  marketing: null|{ [key: string]: string } = null
) => {
  const user = loginType;

  const allLanguages = getLangs();
  let currentLang = localStorage.getItem('lang') as string;
  if (currentLang && allLanguages.includes(currentLang)) {
    user['profile'] = {
      'locale': currentLang
    }
  }

  const fullBody = {
    user
  };

  if (marketing) {
    fullBody['marketing'] = marketing;
  }
  const appropriateUrl = getPrefix() + `/billing/subscribeAsNotLoggedIn/${planUid}`;
  let result: SubscribeResult;
  try {
    result = await apiFetch<SubscribeResult>("", "POST", fullBody, appropriateUrl);
  } catch (e) {
    if (e.isAxiosError){
      throw e.response.data.error;
    }
    throw e;
  }
  return result;
}

const subscribeAsLoggedIn = async (planUid: string, marketing: null|{ [key: string]: string } = null) => {
  const appropriateUrl = getPrefix() + `/billing/subscribeAsLoggedIn/${planUid}`;
  // const result = await apiFetch<SubscribeResult>("", "POST", fullBody, appropriateUrl);
  let result: SubscribeResult;
  try {
    result = await apiFetch<SubscribeResult>("", "POST", marketing == null ? null : {
      marketing
    }, appropriateUrl);
  } catch (e) {
    if (e.isAxiosError){
      throw e.response.data.error;
    }
    throw e;
  }
  return result;
}

const confirmBillingSubscription = async (uuid: string) => {
  const appropriateUrl = getPrefix() + `/billing/confirm/${uuid}`;

  return await apiFetch<StandaloneResponse>('', 'POST', null, appropriateUrl)
}

const unsubBillingSubscription = async (uuid: string) => {
  const appropriateUrl = getPrefix() + `/billing/unsubscribe/${uuid}`;
  return await apiFetch<StandaloneResponse>('', 'POST', null, appropriateUrl)
}

const getUserDetails = async () => {
  const appropriateUrl = getPrefix() + '/billing/user';
  const {payload,token} = await apiFetch<JwtPayloadAndJwtToken>("", "GET", null, appropriateUrl);
  localStorage.setItem("videokubeBearerToken", token);
  return payload;
};

const logout = async () => {
  const appropriateUrl = getPrefix() + '/billing/logout';
  await apiFetch<StandaloneResponse>("", "POST", null, appropriateUrl);
  localStorage.removeItem("videokubeBearerToken");
};

const billingLogin = async (loginType: LoginType) => {
  const appropriateUrl = getPrefix() + '/billing/login';
  const {payload,token} = await apiFetch<JwtPayloadAndJwtToken>("","POST", loginType, appropriateUrl);
  localStorage.setItem("videokubeBearerToken", token);
  return payload;
};

const loginWithHE = async (username: string) => {
  const bodyToStringify = {
    username,
  };
  const appropriateUrl = getPrefix() + '/billing/loginWithHE';
  const {payload,token} = await apiFetch<JwtPayloadAndJwtToken>("","POST",bodyToStringify,appropriateUrl);
  localStorage.setItem("videokubeBearerToken", token);
  return payload;
};

const countryFromIp = async () => {
  const appropriateUrl = getPrefix() + '/countryFromIp';
  return await apiFetch<GeoPayload>("", "GET", null, appropriateUrl);
};

const forgetPassword = async(username:string, captcha: string) => {
  const appropriateUrl = getPrefix() + `/billing/forget_password/${username}`;
  return await apiFetch<StandaloneResponse>("", "POST", {captcha: captcha}, appropriateUrl);
}

export {
  getCategories,
  getScientificPapersRandom,
  getScientificPapersWithMedia,
  getScientificPapersWithCategory,
  getScientificPapersWithKeyword,
  getScientificPaperWithId,
  getScientificPapersWithMediaSlug,
  getScientificPapersWithCategorySlug,
  getVideoLink,
  getChannels,
  getChannel,
  getIdWithSlug,
  getTexts,
  getFiles,
  getInterfaceConfiguration,
  loginDve,
  pingMe,
  getPayloadFromToken,
  getEvents,
  sendMail,
  getUniversities,
  sendRegister,
  confirmRegister,
  getMedias,
  getBillingPlans,
  subscribeAsNotLoggedIn,
  confirmBillingSubscription,
  billingLogin,
  unsubBillingSubscription,
  getUserDetails,
  logout,
  subscribeAsLoggedIn,
  loginWithHE,
  countryFromIp,
  authTokenLogin,
  forgetPassword
};
