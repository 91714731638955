import React from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import { ListItemIcon } from "@material-ui/core";
import { JwtPayload } from "../../types";
import { billingLogin } from "../../api";
import { SocialMediaCallback } from "../SocialMediaCallback";

interface FBButtonProps extends SocialMediaCallback {
  message: string;
  scope: string;
}

declare const window: any;

const useStyles = makeStyles(() =>
  createStyles({
    btnFacebook: {
      cursor: "pointer",
      background: "#3B5998",
      color: "#fff",
      "font-size": "1.2em",
      "font-weight": "bold",
      "&:hover": {
        background: "#527edd"
      },
      padding: "0px",
    },
    icon: {
      fontSize: "3.3rem",
      margin: "0px 7px",
      color: "#fff"
    },
    message: {
      display: "inline-block"
    }
  })
);
export function FBButton(fbProps: FBButtonProps) {
  const classes = useStyles();
  if (fbProps.scope === undefined) {
    fbProps.scope = "email";
  }

  const onLogin = async () => {
    const { authResponse } = await new Promise(resolve =>
      window.FB.login(resolve, { scope: fbProps.scope })
    );

    return billingLogin({
      access_token: authResponse.accessToken,
      provider: "facebook",
      create: true
    }).then((jwt: JwtPayload) => fbProps.callback(jwt));
  };

  return (
    <MenuItem className={classes.btnFacebook} onClick={onLogin}>
      <ListItemIcon>
        <FacebookIcon className={classes.icon} />
      </ListItemIcon>
      <Typography variant="inherit">{fbProps.message}</Typography>
    </MenuItem>
  );
}
