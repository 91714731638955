import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import { Typography, Button, useTheme } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Link } from "react-router-dom";
import { CleverFormattedMessage } from "../Texts/CleverFormattedMessage";

export interface HeaderProps {
  title?: string;
  titleImg?: string;
  img?: string;
  back?: boolean;
  backUrl?: string;
  height?: string;
  color?: string;
  style?: any;
}

HeaderImage.defaultProps = {
  back: false,
  height: 400
};

const useStyles = (extendsStyle:any) => makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      backgroundSize: "cover",
      backgroundPosition: "center",
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      position: "relative",
      backgroundRepeat: "no-repeat"
    },
    text: {
      padding: '100px 0px',
      color: "white",
      textTransform: "uppercase",
      letterSpacing: "10.8px",
      fontWeight: "bold",
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
        fontSize: "2em",
        letterSpacing: "6px"
      }
    },
    back: {
      position: "absolute",
      top: "15px",
      left: "20px",
      color: "white",
      letterSpacing: "2px",
      fontWeight: "bold"
    },
    svg: {
      fontSize: "1rem"
    },
    img: {
      maxHeight: '100%',
      maxWidth: '100%',
    },
    ...extendsStyle
  })
);


export function HeaderImage(props: HeaderProps) {
  const theme = useTheme<Theme>();
  let backgroud = theme.palette.primary.main;
  if(props.color){
    backgroud = props.color;
  }
  let classes = useStyles({})();
  if(props.style){
    classes = useStyles(props.style)();
  } 

  return (
    <React.Fragment>
      {props.img && (
        <Box
          className={classes.root}
          style={{ backgroundImage: "url(" + props.img + ")", height: props.height }}
        >
          {props.back && (
            <Link to={props.backUrl || "/topics"}>
              <Button className={classes.back}>
                <ArrowBackIosIcon className={classes.svg} />
                <CleverFormattedMessage id="back"/>
              </Button>
            </Link>
          )}
          {props.title &&
            <Typography component="h3" variant="h3" className={classes.text}>
              {props.title}
            </Typography>
          }
          {props.titleImg &&
            <img className={classes.img} src={props.titleImg} />
          }
        </Box>
      )}
      {!props.img &&(
        <Box
        className={classes.root}
        bgcolor={ backgroud }
        >
          {props.back && (
            <Link to={props.backUrl || "/topics"}>
              <Button className={classes.back}>
                <ArrowBackIosIcon className={classes.svg} />
                <CleverFormattedMessage id="back"/>
              </Button>
            </Link>
          )}
          {props.title &&
            <Typography component="h3" variant="h3" className={classes.text}>
              {props.title}
            </Typography>
          }
          {props.titleImg &&
            <img className={classes.img} src={props.titleImg} />
          }
        </Box>
      )}
    </React.Fragment>

  );
}
